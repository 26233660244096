type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function Restrictions({ pageRef }: IPageRef) {
  return (
    <div
      className="mt-[25px]"
      ref={pageRef}
      title="Restrictions and Prohibited Material"
    >
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>6.</span> Restrictions and Prohibited Material
      </h1>
      <div className="flex flex-col gap-[15px] mt-[15px]">
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>6.1</span> Restrictions
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            To use the Services, you must comply with this Agreement, including
            these Terms, any applicable third-party policies, and all relevant
            laws, rules, regulations, and guidance. The following activities are
            strictly prohibited:
          </p>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Using the Services for illegal purposes, such as money laundering,
              financing terrorism, tax evasion, or buying/selling illegal drugs,
              contraband, counterfeit goods, or illegal weapons.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Exploiting the Services for any unauthorized commercial
              activities.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Uploading or transmitting viruses, worms, Trojan horses, malware,
              or any other malicious code that may disrupt the functionality or
              operation of the Services.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Unauthorized copying or use of all or any part of the Services,
              including attempts to reverse compile, reformat, disassemble, or
              reverse engineer any aspect of the Services.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Harvesting or collecting information from the Services for
              unauthorized purposes.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Using the Services under false or fraudulent pretenses or engaging
              in deceptive behaviour.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Interfering with other users' access to or use of the Services.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Interfering with or circumventing the security features of the
              Services or any third-party systems, networks, or resources used
              in providing the Services.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Engaging in attacks, hacks, denial-of-service attacks,
              interference, or exploits of smart contracts in connection with
              the Services. Note that even if a smart contract technically
              permits certain operations, engaging in such operations may still
              violate our Agreement, including these Terms, and the law.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Engaging in anticompetitive behaviour or any other form of
              misconduct.{" "}
            </li>
          </ul>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            By using the Services, you agree not to engage in any of the
            aforementioned prohibited activities.
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>6.2</span> Violating our rules
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            You agree and acknowledge that if you use the Services to engage in
            conduct that is prohibited by applicable law, we have the right to
            fully or partially restrict or revoke your access to the Services.
            This action may be taken at our sole discretion and can be temporary
            or permanent in nature. We also reserve the right to amend, rectify,
            edit, or otherwise modify transaction data as necessary to address
            any harm or damage caused by a user's violation of this Agreement or
            applicable law. These measures are implemented to protect both us
            and other individuals affected by such violations.
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>6.3</span> Our Right to Investigate any Violations
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            We retain the right to investigate and take legal action in response
            to any suspected breaches of this Agreement, including the Terms. To
            comply with applicable laws, regulations, legal processes, or
            governmental requests, we may disclose any necessary information.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Restrictions;
