import { FC } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../common/footer";


const MainLayout: FC = () => {
  return (
        <>
        <section className="content w-full h-full">
          <Outlet />
        </section>
  
        <section className="footer-wrapper w-full h-full">
          <Footer />
        </section>
        </>
    );
}

export default MainLayout