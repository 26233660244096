type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function InformationResource({ pageRef }: IPageRef) {
  return (
    <div
      className="mt-[25px]"
      ref={pageRef}
      title="www.shiga.io is provided as an informational resource"
    >
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>2.</span> <span className="text-hbrand-500">www.shiga.io</span> is
        provided as an informational resource
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Shiga.io provides resources that explain the fundamentals of the Shiga
        Protocol. However, all information provided on the Site and through the
        Services is for informational purposes only. You should not make any
        decisions or take any actions solely based on the information found on
        the Site or any other information provided, including blog posts, data,
        articles, links to third-party content, Discord content, news feeds,
        tutorials, tweets, and videos. Before making any financial, legal,
        technical, or other decisions related to the Services, it is recommended
        that you seek independent professional advice from qualified individuals
        in the relevant field.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        The Services may provide links to other sites, applications, or
        resources, but we are not responsible for the availability of such
        external resources, and we do not endorse or accept liability for any
        content, advertising, products, or other materials on or accessible from
        these external sources. You acknowledge that we will not be held
        responsible or liable for any damages or losses incurred from using or
        relying on such content, goods, or services available through these
        external sources.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        It is important to note that the information provided on the Site about
        the Shiga Protocol is not exhaustive and may not cover all aspects of
        the Protocol. Additional documentation is available on the Site for a
        more comprehensive understanding of the Protocol, its ecosystem, and its
        community.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Please be aware that we are not a party to any transactions on the
        blockchain networks underlying the Shiga Protocol. We do not have
        control over any crypto assets displayed on the Services, nor do we
        store, send, or receive any crypto assets. When interacting with Shiga
        Protocol smart contracts, you maintain control over your crypto assets
        at all times using your private key. It is your responsibility to secure
        your private keys, and we do not have access to them. As a non-
        custodial and decentralized service, we are not intermediaries, agents,
        advisors, or custodians. We do not have a fiduciary relationship or
        obligation to you regarding any decisions or activities you undertake
        while using our Services.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Additionally, we do not have access to any user information or
        identities beyond what is publicly available on the blockchain. We are
        not liable for any activities you engage in while using the Services,
        and you need to understand the risks associated with crypto assets,
        blockchain technology, and our Services.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        The software underlying the blockchain networks on which the Shiga
        Protocol operates, such as the Ethereum blockchain, is open-source,
        allowing anyone to use, utilize, and build upon it. By using the
        Services, you acknowledge and agree that we are not responsible for the
        operation of the underlying blockchain-based software and networks. We
        cannot guarantee the functionality, security, or availability of these
        software and networks, and they are subject to sudden changes in
        operating rules, including forks.
      </p>
      <div className="flex flex-col gap-[15px] mt-[15px]">
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>2.1</span> Blockchain Transactions are Traceable
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            Blockchains, including blockchain-based transactions, are designed
            to be transparent. Your public key and wallet address, which are
            necessary for buying or selling items on the blockchain, are visible
            to anyone. If your public key or wallet address can be linked to
            your identity, someone could potentially determine your identity and
            the crypto-currency assets you possess.
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>2.2</span> Blockchain Transactions May Incur Fees
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            It's important to note that blockchain transactions often incur
            associated fees. These fees, known as gas fees, are transaction fees
            paid for every transaction that takes place on the chosen blockchain
            network. Please be aware that gas fees are non-refundable. We do not
            offer services for users to handle, store, or receive payments for
            crypto-currency assets, nor do we charge any fees for transactions,
            the Services, or the Site.
          </p>
        </div>
      </div>
    </div>
  );
}

export default InformationResource;
