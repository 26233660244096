type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function Introduction({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Welcome to SHIGA">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>1.</span> Welcome to SHIGA
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        SHIGA DIGITAL LIMITED’s (“Shiga”) Decentralised Application (DAPP)
        platform is committed to creating open-source software that empowers
        users’ financial autonomy. It provides users with the option to execute
        third party payments and grants access to non-custodial or self-custody
        wallets.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        <b>ARBITRATION NOTICE:</b> THESE TERMS (“TERMS”) CONTAIN AN ARBITRATION
        CLAUSE BELOW. EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN THAT
        ARBITRATION CLAUSE, YOU AND WE AGREE THAT ANY DISPUTES RELATING TO THE
        SERVICES (AS DEFINED BELOW) WILL BE RESOLVED BY MANDATORY BINDING
        ARBITRATION, AND YOU WAIVE ANY RIGHT TO A TRIAL BY JURY OR TO
        PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS- WIDE ARBITRATION.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        You are entering into a binding Agreement.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        BY ACCESSING OR USING OUR SERVICES, INCLUDING OUR VARIOUS WEBSITES SUCH
        AS WWW.SHIGA.IO (AND ITS SUBDOMAINS), APPLICATIONS, AND OTHER SERVICES
        THAT LINK TO THESE TERMS, ALONG WITH ANY INFORMATION, TEXT, LINKS,
        GRAPHICS, PHOTOS, AUDIO, VIDEO, OR OTHER MATERIALS STORED, RETRIEVED, OR
        APPEARING ON THE SITE, WHETHER ACCESSED THROUGH THE SITE OR OTHERWISE,
        YOU ARE ENTERING INTO A BINDING AGREEMENT WITH US (“AGREEMENT”). THIS
        AGREEMENT CONSISTS OF THESE TERMS, THE SHIGA PRIVACY POLICY (AVAILABLE
        HERE), AND OTHER POLICIES REFERENCED HEREIN. COLLECTIVELY, THESE
        DOCUMENTS FORM THE AGREEMENT BETWEEN YOU AND US.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Where there is a conflict between these Terms and any applicable
        additional terms, these Terms will be superior unless expressly stated
        otherwise. If you do not agree with these Terms, you may not use the
        \services and should not visit the Website or otherwise engage with the
        services.{" "}
      </p>
      <div className="flex flex-col gap-[15px] mt-[15px]">
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>1.1</span> Use of the Services
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            To use the Services, you must have the legal capacity to enter into
            the Agreement. By using the Services, you affirm and represent that
            you meet this eligibility criteria. If you do not meet these
            requirements, you must refrain from accessing or using the Site or
            the Services.
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>1.2</span> Updates to Services and the Terms
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            We reserve the right to update the Services, the Agreement, and/or
            any part of the Terms at any time.
          </p>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            By continuing to use the Services, including accessing the Site,
            after any part of the Agreement is updated and in effect, you are
            bound by the Terms. We hold the right to modify or discontinue all
            or any part of the Services, including access through third-party
            links, at any time without liability to you. If you have any
            inquiries regarding your use of the Services, you can reach us at{" "}
            {""}
            <span className="text-hbrand-500">
              <a href="http://www.shiga.io">www.shiga.io</a>
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
