type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function ContactInformation({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Contact Us">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>15.</span> Contact Us
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        For any requests about this Privacy Policy, to exercise any of your
        other rights, or if you have a complaint, contact us at{" "}
        <a href="mailto:admin@shiga.io">
          <i className="font-[600] text-hbrand-500">admin@shiga.io</i>
        </a>{" "}
        You can also contact your local data protection authority.
      </p>
    </div>
  );
}

export default ContactInformation;
