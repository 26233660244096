type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function DataWeCollect({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Data We Collect">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>2.</span> Data We Collect
      </h1>
      <div className="flex flex-col gap-[15px] mt-[15px]">
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>2.1</span> Personal Information/Data provided to us directly
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            The type of personal information that we may collect includes
            current and historical information including your name and contact
            details (such as your address, email address and telephone numbers)
            and other identifiers such as your organization/ employment
            information, itinerary, location, emergency contacts, financial and
            payment information, passport or other identification details, tax
            return slips.
          </p>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            In addition to this, we also collect information automatically from
            the computer, phone, tablet, or other device you use to access our
            services. This includes the IP address, the browser used, and your
            language settings, the date and time you accessed our services, the
            hardware, software of your device, or information about your
            computer’s operating system like application versions etc. There are
            also situations when we receive information about you from others or
            automatically collect other information. We may use a third party
            service provider to manage our contact database and deliver emails
            to inform you about our services, promotional offers and updates
            concerning our business or service offerings or events.
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>2.2</span> Personal Information/Data provided to us from
            third-party applications
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            We may receive information about you from third-parties including
            social media platforms (such as Instagram, LinkedIn and Facebook),
            public sources and third-party data providers. This information may
            include the user ID associated with the social media account and any
            other information you have permitted the third party to share with
            us.
          </p>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            We may also receive information about you and your engagement with
            our advertisements from our ad servers, ad networks and social media
            platforms. This may include websites you visited before coming to
            Shiga
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>2.3</span> Information we collect from you automatically
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            Information regarding the use of our Service is collected
            automatically. This includes but is not limited to user activity
            data, analytics event data, and clickstream data.
          </p>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            This information helps us measure traffic and usage trends for our
            Service. We may also use third party analytics tools that
            automatically collect information send by your browser or mobile
            device, including the pages you visit and other information that
            assists us in improving the Service.
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>2.4</span> Location Data
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            Shiga collects information in order to understand where its users
            are located. It helps us localize and personalize content, comply
            with local laws and improve advertising efficiency.
          </p>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            This information is collected when you provide your location during
            a purchase order, from your IP address, and from our partners or
            your payment provider
          </p>
        </div>
      </div>
    </div>
  );
}

export default DataWeCollect;
