type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function WhyWeCollectData({ pageRef }: IPageRef) {
  return (
    <div
      className="mt-[25px]"
      ref={pageRef}
      title="Why We Collect and Use Your Personal Data"
    >
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>3.</span> Why We Collect and Use Your Personal Data
      </h1>
      <div className="flex flex-col gap-[15px] mt-[15px]">
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>3.1</span> The main reasons we collect personal data are to:
          </h1>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              help you make and manage your reservations and process your
              service requests;
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              render customer service as well as ensure you get the best
              possible service;{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              communicate with you as may be necessary with respect to the
              services being procured;
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Marketing activities and improving our service;
            </li>
          </ul>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            The data we collect enables us to identify clients who have visited
            our website and we use this information to compile statistical data
            on your use of the site to help us to improve the user experience
            and. Additionally, we also use your personal data to contact you
            about the latest deals, special offers, and other products or
            services we think you might be interested in as well as conduct
            market research.
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>3.2</span> How we use your information
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            The primary reason we may process or share your data is to provide
            our service provider with the relevant information to consider
            whether we can provide the services being requested and complete
            your service request. We will only use your personal information if
            and to the extent that applicable law allows.
          </p>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            Nevertheless, we will only process your personal information if:
          </p>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              it is necessary for the performance of a contract with you or any
              organization or third party with whom you are affiliated with;
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              it is necessary in connection with a legal obligation;
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              you have given your consent (where necessary) to such use or the
              organisation you work for has obtained your consent (where
              necessary) to share your information with us; or
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              if we (or a third party) have a legitimate interest which is not
              overridden by your interests or your rights and freedoms. Such
              legitimate interests include the provision of our services,
              running our business and marketing relevant services directly to
              you.{" "}
            </li>
          </ul>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            In particular, we may use your personal information to:{" "}
          </p>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              consider our ability to fulfil your service request;
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              comply with any legal obligations to identify and verify the
              identity of our clients;{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              maintain and develop our business relationship with you;
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              improve our services and product offerings to you;
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              identify additional services you may be interested in;{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              send you targeted marketing information;{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              process and respond to your requests, enquiries or complaints.{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default WhyWeCollectData;
