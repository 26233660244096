type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function Disclaimers({ pageRef }: IPageRef) {
  return (
    <div
      className="mt-[25px]"
      ref={pageRef}
      title="Disclaimers and Liability Limitations"
    >
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>7.</span> Disclaimers and Liability Limitations
      </h1>
      <div className="flex flex-col gap-[15px] mt-[15px]">
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>7.1</span> No Representations or Warranties
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            THE SERVICES ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS.
            THE SHIGA INDEMNIFIED PARTIES, INCLUDING US AND OUR PARENTS,
            SUBSIDIARIES, AFFILIATES, RELATED COMPANIES, OFFICERS, DIRECTORS,
            EMPLOYEES, AGENTS, REPRESENTATIVES, PARTNERS, AND LICENSORS, MAKE NO
            GUARANTEES OF ANY KIND REGARDING THE SERVICES. TO THE MAXIMUM EXTENT
            PERMITTED BY APPLICABLE LAW, THE SHIGA INDEMNIFIED PARTIES DISCLAIM
            ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, INCLUDING
            BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON- INFRINGEMENT. THE SHIGA INDEMNIFIED
            PARTIES ALSO DISCLAIM ALL RESPONSIBILITY AND LIABILITY FOR THE
            FOLLOWING:
          </p>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              THE ACCURACY, COMPLETENESS, CURRENCY, RELIABILITY, UNINTERRUPTED
              AVAILABILITY, TIMELINESS, SECURITY, OR ERROR-FREE NATURE OF THE
              SERVICES. INFORMATION AVAILABLE THROUGH THE SERVICES, INCLUDING
              THE VALUE OR OUTCOME OF ANY TRANSACTION, IS PROVIDED FOR GENERAL
              INFORMATION PURPOSES ONLY AND SHOULD NOT BE SOLELY RELIED UPON FOR
              MAKING DECISIONS. ANY RELIANCE ON THE SERVICES IS AT YOUR OWN
              RISK.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              INJURY OR DAMAGE RESULTING FROM THE USE OF THE SERVICES. FOR
              INSTANCE, YOU ACKNOWLEDGE AND AGREE THAT THE SERVICES MAY CONTAIN
              AUDIO-VISUAL EFFECTS, STROBE LIGHTS, OR OTHER MATERIALS THAT MAY
              AFFECT YOUR PHYSICAL SENSES AND/OR CONDITION. FURTHERMORE, YOU
              ACKNOWLEDGE THAT THE SHIGA INDEMNIFIED PARTIES ARE NOT RESPONSIBLE
              FOR ANY LOSS OR DAMAGE CAUSED BY ANOTHER USER'S CONDUCT,
              UNAUTHORIZED ACTORS, OR UNAUTHORIZED ACCESS TO OR USE OF THE
              SERVICES.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              VIRUSES, WORMS, TROJAN HORSES, TIME BOMBS, CANCEL BOTS, SPIDERS,
              MALWARE, OR ANY OTHER TYPE OF MALICIOUS CODE THAT MAY BE USED TO
              AFFECT THE FUNCTIONALITY OR OPERATION OF THE SERVICES.
            </li>
          </ul>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>7.2</span> Limitation of Liability
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, NONE OF THE SHIGA
            INDEMNIFIED PARTIES SHALL BE LIABLE TO YOU FOR ANY LOSS, DAMAGE, OR
            INJURY OF ANY KIND, INCLUDING DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
            EXEMPLARY, CONSEQUENTIAL, OR PUNITIVE LOSSES OR DAMAGES. THIS
            INCLUDES DAMAGES RESULTING FROM SYSTEM FAILURE OR MALFUNCTION, LOSS
            OF PROFITS, DATA, USE, BUSINESS, GOODWILL, OR OTHER INTANGIBLE
            LOSSES. SUCH LIABILITY SHALL ARISE OUT OF OR BE CONNECTED TO:
          </p>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              THE SERVICES OR YOUR INABILITY TO USE OR ACCESS THE SERVICES.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              MISUSE OF THE SERVICES, INCLUDING UNAUTHORIZED ACCESS TO THE
              SERVICES.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              ANY USER CONDUCT ON THE SERVICES.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              TERMINATION, SUSPENSION, OR RESTRICTION OF ACCESS TO ANY OF THE
              SERVICES.{" "}
            </li>
          </ul>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            FURTHERMORE, NO SHIGA INDEMNIFIED PARTY SHALL BE LIABLE FOR ANY
            DAMAGES CAUSED IN WHOLE OR IN PART BY:
          </p>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY CONSTRUCTED
              SMART CONTRACTS OR TRANSACTIONS.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              SERVER FAILURE OR DATA LOSS.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              MALFUNCTIONS, UNEXPECTED FUNCTIONS, OR UNINTENDED FUNCTIONS OF THE
              BLOCKCHAIN, ANY COMPUTER, OR CRYPTO ASSET NETWORK (INCLUDING
              WALLET PROVIDERS). THIS INCLUDES LOSSES ASSOCIATED WITH NETWORK
              FORKS, REPLAY ATTACKS, DOUBLE-SPEND ATTACKS, SYBIL ATTACKS, 51%
              ATTACKS, GOVERNANCE DISPUTES, MINING DIFFICULTY, CHANGES IN
              CRYPTOGRAPHY OR CONSENSUS RULES, HACKING, OR CYBERSECURITY
              BREACHES.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              CHANGES IN THE VALUE OF ANY CRYPTO ASSET.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              CHANGES IN LAW, REGULATION, OR POLICY.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              EVENTS OF FORCE MAJEURE. (G) ANY THIRD PARTY.
            </li>
          </ul>
        </div>
      </div>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        THIS LIMITATION OF LIABILITY APPLIES REGARDLESS OF WHETHER OTHER
        PROVISIONS OF THESE TERMS HAVE BEEN BREACHED OR PROVEN INEFFECTIVE. THE
        LIMITATIONS OUTLINED IN THIS SECTION APPLY REGARDLESS OF THE FORM OF
        ACTION AND THE LEGAL OR EQUITABLE THEORY UPON WHICH LIABILITY OR DAMAGES
        ARE BASED, WHETHER IT BE CONTRACT, INDEMNIFICATION, TORT, STRICT
        LIABILITY, STATUTE, OR ANY OTHER THEORY, EVEN IF THE SHIGA INDEMNIFIED
        PARTIES HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        IN NO EVENT WILL THE CUMULATIVE LIABILITY OF THE SHIGA INDEMNIFIED
        PARTIES TO YOU OR ANY OTHER USER EXCEED ONE THOUSAND U.S. DOLLARS (U.S.
        $1,000.00).
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        UNDER NO CIRCUMSTANCES ARE ANY OF THE SHIGA INDEMNIFIED PARTIES REQUIRED
        TO PROVIDE YOU WITH VIRTUAL CURRENCY AS DAMAGES, SPECIFIC PERFORMANCE,
        OR ANY OTHER REMEDY. IF THE CALCULATION OF DAMAGES IS BASED ON THE VALUE
        OF VIRTUAL CURRENCY, YOU AND WE AGREE THAT THE CALCULATION WILL BE BASED
        ON THE LOWEST VALUE OF THE VIRTUAL CURRENCY DURING THE PERIOD BETWEEN
        THE ACCRUAL OF THE CLAIM AND THE AWARD OF DAMAGES.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Please note that some jurisdictions may not allow the exclusion or
        limitation of certain warranties and liabilities as stated in this
        section. As a result, some of the limitations and disclaimers mentioned
        above may not apply to you. In cases where applicable law does not
        permit the SHIGA Indemnified Parties to disclaim certain warranties or
        limit certain liabilities, the extent of the SHIGA Indemnified Parties'
        liability and the scope of such warranties will be as permitted under
        the applicable law.
      </p>
    </div>
  );
}

export default Disclaimers;
