type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function Taxes({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Taxes">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>4.</span> Taxes
      </h1>
      <div className="flex flex-col gap-[15px] mt-[15px]">
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>4.1</span> Tax Duty and Responsibility
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            Users are solely responsible for fulfilling their tax obligations,
            including the payment of any taxes, duties, and assessments imposed
            by governmental authorities, both currently and in the future,
            related to their use of the Services. This responsibility also
            extends to any taxes or obligations resulting from the use and
            exploitation of crypto assets and interactions with smart contracts.
            It should be noted that the tax treatment of blockchain-based
            transactions is uncertain due to their novelty.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Taxes;
