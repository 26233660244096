import { FC, useRef, useState } from "react";
import {
  coin1,
  coin2,
  coin3,
  coin4,
  coin5,
  coin6,
  coin7,
  coin8,
  coin9,
  coin10,
  plus,
  minus,
  topDrop,
  step1,
  step2,
  step3,
  topDropMob,
  stepMob1,
  stepMob2,
  stepMob3,
} from "../../common/svg";
import { Link } from "react-router-dom";
import { useMobile } from "../../utlis";

const InfiniteScroll = ({ coins }: { coins: string[] }) => {
  const coinContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={coinContainerRef}
      className="bottom-coins w-[74%] h-auto absolute left-0 right-0 m-auto bottom-[5.65rem] shadow-s-thicker rounded-2xl bg-white py-5 px-4 z-20 flex items-center gap-x-3.5 overflow-x-hidden hide-scrollbar cursor-pointer infinite-scroll"
    >
      {/* Clone the content multiple times for continuous scrolling */}
      <div className="infinite-scroll-content">
        {[...coins, ...coins].map((coin, index) => (
          <span
            key={index}
            className="hover:scale-150"
            dangerouslySetInnerHTML={{ __html: coin }}
          />
        ))}
      </div>
    </div>
  );
};

const Business: FC = () => {
  const isMobile = useMobile();

  const coinArray = [
    coin1,
    coin2,
    coin3,
    coin4,
    coin5,
    coin6,
    coin7,
    coin8,
    coin9,
    coin10,
  ];

  const [openFAQIndex, setOpenFAQIndex] = useState<{
    section: number | null;
    index: number | null;
  }>({ section: null, index: null });

  // Toggle function to handle opening/closing of the FAQs
  const toggleFAQ = (section: number, index: number) => {
    // If the same FAQ is clicked, close it; otherwise, open the new one
    if (openFAQIndex.section === section && openFAQIndex.index === index) {
      setOpenFAQIndex({ section: null, index: null });
    } else {
      setOpenFAQIndex({ section, index });
    }
  };

  const faqContent1 = [
    {
      question: "What is Shiga?",
      answer:
        "Shiga Digital is leading the way in creating a cutting-edge blockchain-based bank with a special focus on Pan Africa. Say goodbye to traditional finance and hello to the new era of financial empowerment for Pan-Africa! We're building the core components to redefine the financial landscape, providing liquidity through stablecoins, seamless payments, and access to real-world assets. Our self-custodial wallets will empower you to take control of your assets like never before. Shiga is paving the way for the next wave of enterprises and individuals seeking innovative financial services. Get ready to join the movement!",
    },
    {
      question: "Why did we build Shiga?",
      answer:
        "Blockchain-based finance has the potential to transform access to finance, with a handful of companies already leading this revolution. However, to make this work for the benefit of all and truly achieve scale, we need to fix the disconnect between the current system and the immaturity of the new system. Shiga is driving towards a future where both systems coexist harmoniously, bringing the best of both worlds to businesses, banks, and payment providers.",
    },
    {
      question: "Is Shiga regulated?",
      answer:
        "Shiga's VASP is registered in the Czech Republic, which enables Shiga to engage in digital asset services in Europe. Specifically, this includes the intermediation of trade and services, wholesale and retail trade, software provision, information technology consultancy, data processing, hosting and related activities, web portals, and virtual asset services.",
    },
    {
      question: "How is shiga regulated out of europe?",
      answer:
        "Outside of Europe, Shiga Digital, Inc. is a Delaware-based C Corporation Leveraging regulated entities to provision subsequent gateways in supported regions.",
    },
  ];

  const faqContent2 = [
    {
      question: "What exactly are stablecoins?",
      answer:
        "Stablecoins are digital currencies designed to provide stability. They are pegged to stable assets like the US dollar and backed by equivalent US Treasuries reserves. Issued by regulated entities such as Circle or Coinbase, stablecoins combine cryptocurrencies' advantages with traditional currencies' stability.",
    },
    {
      question: "What is a self-custody wallet? ",
      answer:
        " Non-custodial means that you're always in control. Unlike traditional banks, Shiga never has unilateral access to your funds. There’s no rehypothecation without your consent—your assets are yours. Our platform ensures complete transparency and control, setting a new financial freedom and security standard.",
    },
    {
      question: "How long does it take for transactions to process?",
      answer:
        "We aim to make every Shiga Powered transaction feel instant. Most portal transactions are processed in less than five minutes using your Shiga portals. Shiga uses blockchain technology to process all orders instantly (sending them for clearing immediately). Transaction times may vary depending on local banking processing times.",
    },
    {
      question: "Does Shiga have a mobile application?",
      answer: "Coming soon 😃",
    },
  ];

  return (
    <div className="business-wrapper relative">
      <section className="business-container w-full h-full bg-white">
        <div
          className={`business-hero relative flex items-start justify-between bg-white pb-8 z-10 w-full ${
            isMobile
              ? "pt-[8.5rem] h-[578px] items-center"
              : "pt-12 h-[620px] items-start"
          } -mt-16`}
        >
          <div
            className={`business-cont ${
              isMobile
                ? "px-[24px] w-full -mt-[0.5rem] items-center"
                : "pl-32 w-[790px] mt-[9rem]"
            } absolute flex flex-col justify-center z-20`}
          >
            <h6
              className={`font-ibm font-medium ${
                isMobile ? "text-[11px]" : "text-[10.5px]"
              } w-fit px-2 py-[0.5px] rounded-2xl text-hbrand-700 bg-hbrand-50 border border-hbrand-200`}
            >
              BUSINESS
            </h6>
            <h1
              className={`text-htext-main ${
                isMobile
                  ? "text-[31px] leading-[38px] text-center"
                  : "text-[43px] leading-[58px]"
              } font-satoshi font-black mt-[24px]`}
            >
              Power Your Business with Blockchain Finance
            </h1>
            <p
              className={`text-htext-label ${
                isMobile ? "text-[19px] text-center" : "text-[17.5px]"
              } font-inter font-normal mt-[24px]`}
            >
              Streamline your operations with decentralized financial tools.
              Access global liquidity, manage assets, and make secure payments
              effortlessly.
            </p>
            <div
              className={`mt-[40px] flex items-center ${
                isMobile ? "flex-col w-full" : ""
              }`}
            >
              <a href="https://business.shiga.io/signup">
                <button
                  className={`${
                    isMobile ? "px-[2.1rem]" : "w-[275px]"
                  } py-[9.5px] text-[14.5px] text-white font-ibm font-semibold rounded-[7px] border border-hbrand-600 bg-hbrand-600 hover:bg-hbrand-700 hover:border-hbrand-700 shadow-s-light`}
                >
                  Get started as a business
                </button>
              </a>
              <Link to="/case-study/do-business-in-africa-without-an-african-bank-account">
                <p
                  className={`text-hbrand-700 text-[14px] font-ibm font-bold ${
                    isMobile ? "text-center mt-[2.3rem]" : "ml-[38px]"
                  }`}
                >
                  View case study
                </p>
              </Link>
            </div>
          </div>

          <div
            className={`${
              isMobile ? "hidden" : "flex"
            } w-[490px] absolute mt-[7rem] right-36`}
          >
            <img
              className="w-full"
              src="/assets/home-busi.svg"
              alt="Business World Wide"
            />
          </div>
        </div>
      </section>

      <section className="business-container w-full h-full bg-white">
        <div className="busi-solu relative bg-white">
          <div
            className={`solu-top pt-[33px] pb-[24px]  ${
              isMobile ? "px-[30px]" : ""
            } flex flex-col items-center text-center`}
          >
            <h2
              className={`font-satoshi font-black text-hgray-950 ${
                isMobile ? "text-[23px] leading-8" : "text-[30.5px]"
              }`}
            >
              Explore our business solutions in detail
            </h2>
            <span
              className="arrow-pointer relative mt-[20.5px] z-10 cursor-pointer hover:opacity-80"
              dangerouslySetInnerHTML={{
                __html: isMobile ? topDropMob : topDrop,
              }}
              onClick={() => {
                window.scrollBy({
                  top: 800, // Scrolls 150px downward
                  behavior: "smooth", // Smooth scroll effect
                });
              }}
            />
          </div>

          <div
            className={`liquidity-bottom relative py-[88px] ${
              isMobile ? "px-[24px] flex-col" : "px-[148px]"
            } flex items-center justify-between`}
          >
            <div
              className={`bottom-right relative ${
                isMobile ? "w-full" : "w-[42%] pl-5"
              } flex flex-col justify-center`}
            >
              <h3
                className={`mt-3.5 font-satoshi font-bold ${
                  isMobile
                    ? "text-[29.5px] leading-[38px]"
                    : "text-[36.5px] leading-[48px]"
                } text-hgray-950`}
              >
                Access Liquidity Across Blockchains
              </h3>
              <p
                className={`mt-6 font-ibm font-normal text-htext-label ${
                  isMobile ? "w-full text-[16.5px]" : "w-[430px] text-[17.5px]"
                }`}
              >
                Easily use stablecoins for payments and get cash in your bank
                account, or move money between different blockchain networks.
              </p>
            </div>

            <div
              className={`bottom-left bottom-liquid relative ${
                isMobile ? "w-full h-[300px] mt-8" : "w-[51%] h-[490px]"
              } flex flex-col justify-center`}
            >
              <img
                className={`${
                  isMobile ? "w-full" : " w-[100%]"
                } relative z-20 m-auto object-cover -top-3.5 left-2`}
                src="/assets/liquidity.svg"
                alt=""
              />
              {!isMobile && <InfiniteScroll coins={coinArray} />}
            </div>
          </div>

          <div
            className={`liquidity-bottom relative pb-[88px] ${
              isMobile
                ? "pt-[8px] px-[24px] flex-col-reverse"
                : "pt-[11px] px-[148px]"
            } flex items-center justify-between`}
          >
            <div
              className={`bottom-left relative ${
                isMobile ? "w-full h-[300px] mt-8" : "w-[52%] h-[490px]"
              } flex flex-col justify-center`}
            >
              <img
                className={`${
                  isMobile ? "w-[55%]" : "w-[45%]"
                } relative z-20 m-auto hover:scale-105 object-cover`}
                src="/assets/swaps.svg"
                alt=""
              />
            </div>

            <div
              className={`bottom-right relative ${
                isMobile ? "w-full" : "w-[43%] -pl-4"
              } flex flex-col justify-center -pl-4`}
            >
              <h3
                className={`mt-3.5 font-satoshi font-bold ${
                  isMobile
                    ? "text-[29.5px] leading-[38px]"
                    : "text-[37.5px] leading-[48px]"
                } text-hgray-950`}
              >
                Swap between Stablecoins and Cash
              </h3>
              <p
                className={`mt-6 font-ibm font-normal text-htext-label ${
                  isMobile ? "w-full text-[16.5px]" : "w-[430px] text-[17.5px]"
                }`}
              >
                Easily switch between stablecoins and regular money for your
                business, wherever you are. Instantly handle payments in both
                the digital world of crypto and the traditional banking system
                without any hassle.
              </p>
            </div>
          </div>

          <div
            className={`liquidity-bottom relative pb-[60px] ${
              isMobile ? "pt-[8px] px-[24px] flex-col" : "pt-[11px] px-[148px]"
            } flex items-center justify-between`}
          >
            <div
              className={`bottom-right relative ${
                isMobile ? "w-full" : "w-[42%] pl-5"
              } flex flex-col justify-center`}
            >
              <h3
                className={`mt-3.5 font-satoshi font-bold ${
                  isMobile
                    ? "text-[29.5px] leading-[38px]"
                    : "text-[37.5px] leading-[48px]"
                } text-hgray-950`}
              >
                Spend your Stablecoins Like Cash, without Losing Control
              </h3>
              <p
                className={`mt-6 font-ibm font-normal text-htext-label ${
                  isMobile ? "w-full text-[16.5px]" : "w-[450px] text-[17.5px]"
                }`}
              >
                Quit parking your treasury with a bank or fintech. Keep your
                stablecoins with you at all times and use them instantly
                whenever you need to make a payment.
              </p>
            </div>

            <div
              className={`bottom-left bottom-gas relative ${
                isMobile ? "w-full h-[300px] mt-8" : "w-[50%] h-[490px]"
              } flex flex-col justify-center`}
            >
              <img
                className={`${
                  isMobile ? "w-[65%]" : "w-[70%]"
                } relative z-20 m-auto hover:scale-105 object-contain`}
                src="/assets/gaseless.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="business-container w-full h-full bg-hbrand-25">
        <div className="3steps three-steps relative bg-hbrand-25">
          <div
            className={`3steps-cont ${
              isMobile
                ? "px-[40px] pt-[55px] pb-[74px]"
                : "py-[88px] px-[148px]"
            } w-full flex flex-col items-center`}
          >
            <h2
              className={`font-satoshi font-black text-hgray-950${
                isMobile ? "text-[31px] leading-[38px]" : "text-[43.5px]"
              } text-center`}
            >
              Get started in 3 simple steps
            </h2>

            <div
              className={`flex ${
                isMobile ? "flex-col gap-y-12" : "gap-x-6"
              } items-start justify-center w-full mt-[49px]`}
            >
              <div
                className={`flex flex-col items-center justify-center  text-center gap-y-3.5 ${
                  isMobile ? "w-full" : "w-[340px]"
                }`}
              >
                <span
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: isMobile ? stepMob1 : step1,
                  }}
                />
                <h6 className="steps-text1 mt-3 text-htext-main text-[18px] font-satoshi font-black">
                  Create an account
                </h6>
                <p className="steps-text2 text-htext-subdued text-[16.5px] font-ibm font-normal">
                  Sign up with your business email.
                </p>
              </div>

              <div
                className={`flex flex-col items-center justify-center  text-center gap-y-3.5 ${
                  isMobile ? "w-full" : "w-[340px]"
                }`}
              >
                <span
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: isMobile ? stepMob2 : step2,
                  }}
                />
                <h6 className="steps-text1 mt-3 text-htext-main text-[18px] font-satoshi font-black">
                  Verify your account
                </h6>
                <p className="steps-text2 text-htext-subdued text-[16.5px] font-ibm font-normal">
                  Complete quick KYC/KYB checks to unlock full functionality.
                </p>
              </div>

              <div
                className={`flex flex-col items-center justify-center text-center gap-y-3.5 ${
                  isMobile ? "w-full" : "w-[340px]"
                }`}
              >
                <span
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: isMobile ? stepMob3 : step3,
                  }}
                />
                <h6 className="steps-text1 mt-3 text-htext-main text-[18px] font-satoshi font-black">
                  Start Using Shiga
                </h6>
                <p className="steps-text2 text-htext-subdued text-[16.5px] font-ibm font-normal">
                  Instantly start managing your assets and payments through our
                  platform.
                </p>
              </div>
            </div>
            <a href="https://business.shiga.io/signup">
              <button className="px-[70px] py-[10.5px] mt-[48px] border rounded-lg bg-hbrand-600 border-hbrand-600 hover:bg-hbrand-700 hover:border-hbrand-700 text-white text-[14.5px] font-ibm font-semibold shadow-s-light">
                Create your account
              </button>
            </a>
          </div>
        </div>
      </section>

      <section className="business-container w-full h-full bg-white">
        <div className="home-faq relative bg-white">
          <div
            className={`faq-cont ${
              isMobile
                ? "px-[24px] pt-[85px] pb-[23rem]"
                : "pt-[88px] pb-[17rem] px-[148px]"
            }`}
          >
            <h2 className="faq-header font-satoshi font-black text-hgray-950 text-[43.5px] text-start">
              FAQ
            </h2>
            <div
              className={`w-full flex ${
                isMobile ? "flex-col" : ""
              } items-start justify-between flex-wrap mt-6`}
            >
              {/* First Column of FAQs */}
              <div
                className={`flex flex-col items-start justify-center ${
                  isMobile ? "w-full" : "w-[48%]"
                }`}
              >
                {faqContent1.map((faq, index) => (
                  <div
                    className={`faq-card w-full cursor-pointer ${
                      index === 0
                        ? "border-y border-y-hgray-200"
                        : "border-b border-b-hgray-200"
                    } py-5`}
                    key={index}
                    onClick={() => toggleFAQ(1, index)}
                  >
                    <div className="flex items-center justify-between">
                      <h3
                        className={`font-ibm font-bold text-[15.5px] text-htext-main ${
                          isMobile ? "pr-1" : ""
                        }`}
                      >
                        {faq.question}
                      </h3>
                      <span
                        className=""
                        dangerouslySetInnerHTML={{
                          __html:
                            openFAQIndex.section === 1 &&
                            openFAQIndex.index === index
                              ? minus
                              : plus,
                        }}
                      />
                    </div>
                    {openFAQIndex.section === 1 &&
                      openFAQIndex.index === index && (
                        <div className="toggle-content mt-2.5">
                          <p className="font-ibm font-normal text-[14.5px] text-htext-label w-[90%]">
                            {faq.answer}
                          </p>
                        </div>
                      )}
                  </div>
                ))}
              </div>

              {/* Second Column of FAQs */}
              <div
                className={`flex flex-col items-start justify-center ${
                  isMobile ? "w-full" : "w-[48%]"
                }`}
              >
                {faqContent2.map((faq, index) => (
                  <div
                    className={`faq-card w-full cursor-pointer ${
                      isMobile && index === 0
                        ? "border-b border-b-hgray-200"
                        : !isMobile && index === 0
                        ? "border-y border-y-hgray-200"
                        : "border-b border-b-hgray-200"
                    } py-5`}
                    key={index}
                    onClick={() => toggleFAQ(2, index)}
                  >
                    <div className="flex items-center justify-between">
                      <h3 className="font-ibm font-bold text-[15.5px] text-htext-main">
                        {faq.question}
                      </h3>
                      <span
                        className=""
                        dangerouslySetInnerHTML={{
                          __html:
                            openFAQIndex.section === 2 &&
                            openFAQIndex.index === index
                              ? minus
                              : plus,
                        }}
                      />
                    </div>
                    {openFAQIndex.section === 2 &&
                      openFAQIndex.index === index && (
                        <div className="toggle-content mt-2.5">
                          <p className="font-ibm font-normal text-[14.5px] text-htext-label w-[90%]">
                            {faq.answer}
                          </p>
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Business;
