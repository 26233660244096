type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function AssumptionOfRisk({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Assumption of Risk">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>3.</span> Assumption of Risk
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Transactions that rely on smart contracts and other experimental
        technologies come with risks. Our transaction protocols operate through
        smart contracts stored on different Blockchains, Cryptographic Tokens
        generated by these smart contracts, as well as other emerging software,
        applications, and systems that interact with blockchain networks.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Apart from the fact that these technologies can be subject to regular or
        frequent modifications, allowing for flexibility and evolution over
        time, they are experimental and inherently risky. Some of these risks,
        including bugs and cyber-attacks, could disrupt the technology and
        potentially lead to the complete loss of crypto assets, their market
        value, or digital funds.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        The security of the private key associated with the blockchain address
        used in interactions with the Protocol is your sole responsibility. We
        are neither liable nor responsible for any of such risks. If you are not
        comfortable assuming these risks, it is advised that you refrain from
        accessing or engaging in transactions using blockchain-based technology.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        IT IS ALSO IMPORTANT TO NOTE THAT ONCE A TRANSACTION IS RECORDED ON THE
        BLOCKCHAIN, INCLUDING TRANSFERS OF CRYPTO ASSETS AND ASSOCIATED DATA
        PROGRAMMED WITHIN THESE ASSETS (SUCH AS REVENUE AND INTEREST
        ALLOCATIONS), TRANSACTIONS GENERALLY CANNOT BE DELETED OR MODIFIED BY US
        OR ANY OTHER PARTY. THEY ARE CONSIDERED PERMANENT AND IRREVERSIBLE. THIS
        IMMUTABILITY REQUIRES CAUTION WHEN FINALIZING ANY TRANSACTION THAT WILL
        BE RECORDED ON THE BLOCKCHAIN.
      </p>
      <div className="flex flex-col gap-[15px] mt-[15px]">
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>3.1</span> Disclaimer:
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            Exclusion of Liability for Third-Party Services or Links We do not
            assume responsibility for the content or services provided by any
            third party. We make no representations regarding the accuracy or
            content of any third-party services or materials. Your use and
            access to any third-party products or services, including those
            accessed through our Services, are undertaken at your own risk.
          </p>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            By using the Services, you agree to the automated collection and
            disbursement of proceeds through smart contracts. You acknowledge
            and accept that all transactions conducted through the Services will
            be automatically processed by one or more blockchain-based smart
            contracts. By engaging in transactions using the Services, you
            acknowledge and consent to the automatic processing of all
            transactions in connection with the Services. Furthermore, you
            acknowledge and agree that the specific smart contract governing a
            transaction will determine the distribution of funds and ownership
            of crypto assets.
          </p>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            You acknowledge the risks associated with using the Services. You
            are solely responsible for evaluating the Services before using
            them, and all transactions accessed through the Services are
            irreversible, final, and non-refundable. The Services may be
            disabled, disrupted, or negatively impacted due to cyber-attacks,
            high activity levels, computer viruses, technical challenges, or
            other factors. We do not have an ongoing obligation to notify you of
            all potential risks associated with using and accessing our
            Services. By accepting these risks, you agree not to hold any Shiga
            Indemnified Party accountable for any resulting losses.
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>3.2</span> Wallet Security: Your Sole Responsibility
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            You acknowledge and agree that you bear sole responsibility for
            ensuring the security of your wallet. Any unauthorized access to
            your wallet by third parties may lead to the loss or theft of crypto
            assets or funds held in your account and any related accounts.
          </p>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            You understand and agree that we are not involved in managing or
            maintaining the security of your wallet, and you will not hold us
            responsible for such matters. Furthermore, you understand and agree
            that we are not accountable for any unauthorized access to your
            wallet. It is your responsibility to actively monitor the security
            of your wallet.{" "}
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>3.3</span> Access Restriction to Our Services
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            You acknowledge and agree that we have the right to restrict your
            access to the Services using any technically available methods if,
            in our sole discretion, we suspect any of the following:
          </p>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              You are using the Services for money laundering or engaging in any
              illegal activity.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              You have been involved in fraudulent activity.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              You have obtained crypto assets using inappropriate methods,
              including the use of stolen funds.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              You are the target of sanctions imposed by authorities such as the
              U.S. Department of the Treasury's Office of Foreign Assets Control
              (OFAC), the United Nations Security Council, the European Union,
              Her Majesty's Treasury, or any other applicable legal or
              regulatory authority.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              You, as an individual or entity, or your wallet address, appear on
              the Specially Designated Nationals and Blocked Persons List (SDN
              List), Consolidated Sanctions List (Non-SDN Lists), or any other
              sanctions lists administered by OFAC.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              You are located, organized, or resident in a country or territory
              that is subject to sanctions, including but not limited to Côte
              d'Ivoire, Cuba, Belarus, Iran, Iraq, Liberia, North Korea, Sudan,
              and Syria.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              You have violated any provisions outlined in these Terms. If we
              have a reasonable suspicion that you are utilizing the Site for
              illegal purposes, we reserve the right to take appropriate action
              as deemed necessary.
            </li>
          </ul>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>3.4</span> Quality or Accessibility of Services
          </h1>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              As a condition for accessing or using the Services or the Site,
              you acknowledge and understand that there may be occasions when
              the Site and the Services are inaccessible or non-functional due
              to various reasons. These reasons may include equipment
              malfunctions, periodic maintenance procedures or repairs, causes
              beyond our control or unforeseeable circumstances, disruptions or
              temporary/permanent unavailability of underlying blockchain
              infrastructure, or unavailability of third-party service providers
              or external partners.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              You acknowledge and agree that when accessing and using the
              Services, including the Site, you do so at your own risk. It is
              important to consider your circumstances and financial resources
              before engaging in blockchain-based transactions. By utilizing the
              Services, you confirm that you are solely responsible for
              conducting your thorough due diligence regarding the risks
              associated with a transaction, as well as the underlying smart
              contracts and crypto assets involved.{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AssumptionOfRisk;
