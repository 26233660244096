type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function SharingYourInformation({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Sharing Your Information">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>4.</span> Sharing Your Information
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        From time to time, we may involve other third parties to process and
        fulfil your service requests. This includes, for example, financial
        institutions; advertising and marketing services; Security; data
        analytics, customer support; billing; technology suppliers’ subsidiaries
        of the Company etc. These service providers are vetted by us, and will
        only be provided with access to your information as is reasonably
        necessary for the purpose that Shiga has engaged the third-party service
        provider.
      </p>

      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        In some instances, we may transfer information, including personal
        information, to a country outside your jurisdiction. We always take
        steps to ensure that your information remains protected wherever it is
        stored and processed in accordance with applicable laws.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        In some cases, if we’re required to by law, we might share your data
        with governmental or other relevant and regulatory authorities.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Shiga does not sell or rent the information we collect directly from you
        or about you from third parties.
      </p>
    </div>
  );
}

export default SharingYourInformation;
