type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function TheServices({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="The Services">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>5.</span> The Services
      </h1>
      <div className="flex flex-col gap-[15px] mt-[15px]">
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>5.1</span> Service Usage License
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            Subject to your continued compliance with the Agreement, we provide
            you with a personal, worldwide, revocable, non-exclusive, and non-
            transferable license to use the software that is made available to
            you as part of our Services. This license is granted solely to allow
            you to use and benefit from the Services in accordance with the
            provisions outlined in these Terms.
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>5.2</span> Service Ownership: All Rights Reserved
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            We retain all rights, title, and interest in and to the Services,
            which includes copyrights to any content, code, data, or other
            materials that you may access or use through the Services. However,
            please note that the code for the Shiga Protocol and the front-end
            interface (<span className="text-hbrand-500">www.shiga.io</span>)
            deployed on IPFS are open-source. Except as explicitly stated here,
            your use or access to the Services does not confer any ownership or
            other rights to you.
          </p>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>5.3</span> Feedback Usage and Sharing
          </h1>
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            Any comments, bug reports, ideas, or other feedback that you
            voluntarily provide about our Services, including suggestions for
            improvements, are considered non-confidential and non-proprietary.
            You agree that we have the freedom to utilize or choose not to use,
            any feedback received from you as we deem appropriate. This includes
            the ability to copy and share such feedback with third parties,
            without any obligation to you.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TheServices;
