type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function YourRights({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Your Rights">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>9.</span> Your Rights
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        We want you to be in control of how your personal data is used by us.
        You can do this in the following ways:
      </p>
      <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          You can ask us for a copy of the personal data we hold about you.
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          You can inform us of any changes (rectify) to your personal data, or
          you can ask us to correct any of the personal data we hold about you
          (you can make some of these changes yourself, online, when you have a
          user account).
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          In certain situations, you can ask us to erase, block or restrict the
          processing of the personal data we hold about you, or object to
          particular ways in which we are using your personal data,
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          In certain situations, you can also ask us to send the personal data
          you've given us to a third party.{" "}
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          Where we use your personal data on the basis of your consent, you’re
          entitled to withdraw that consent at any time, subject to applicable
          law.{" "}
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          Where we process your personal data based on legitimate interest or
          the public interest, you have the right to object to that use of your
          personal data at any time, subject to applicable law.{" "}
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          We rely on you to make sure that your personal info is complete,
          accurate, and current. Kindly let us know about any changes to or
          inaccuracies in your personal info as soon as possible.{" "}
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          request access to your personal information (known as a subject access
          request) and request certain information in relation to its
          processing;{" "}
        </li>
      </ul>
    </div>
  );
}

export default YourRights;
