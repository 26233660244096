import { MutableRefObject, useEffect, useRef, useState } from "react";
import Introduction from "./introduction";
import AssumptionOfRisk from "./assumptionOfRisk";
import Taxes from "./taxes";
import InformationResource from "./informationResource";
import TheServices from "./theServices";
import Restrictions from "./restrictions";
import Disclaimers from "./disclaimers";
import Indemnity from "./indemnity";
import DisputeResolution from "./disputeResolution";
import WaiverInjunction from "./waiverInjunction";
import Termination from "./termination";
import Children from "./children";
import Assignment from "./assignment";
import Agreement from "./agreement";
import SeverabilityB from "./severabilityB";

function TermsOfService() {
  const date = new Date();
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
  };
  const monthYear = date.toLocaleDateString("en-US", options);

  const formattedDate = monthYear.replace(" ", ", ");

  const [refTitle, setRefTitle] = useState("");
  const introductionRef = useRef<HTMLDivElement | null>(null);
  const InformationResourceRef = useRef<HTMLDivElement | null>(null);
  const AssumptionOfRiskRef = useRef<HTMLDivElement | null>(null);
  const TaxesRef = useRef<HTMLDivElement | null>(null);
  const TheServicesRef = useRef<HTMLDivElement | null>(null);
  const DisclaimerRef = useRef<HTMLDivElement | null>(null);
  const IndemnityRef = useRef<HTMLDivElement | null>(null);
  const RestrictionRef = useRef<HTMLDivElement | null>(null);
  const DisputeRef = useRef<HTMLDivElement | null>(null);
  const WaiverInjunctionRef = useRef<HTMLDivElement | null>(null);
  const TerminationRef = useRef<HTMLDivElement | null>(null);
  const ChildrenRef = useRef<HTMLDivElement | null>(null);
  const SeverabilityRef = useRef<HTMLDivElement | null>(null);
  const AssignmentRef = useRef<HTMLDivElement | null>(null);
  const AgreementRef = useRef<HTMLDivElement | null>(null);

  const termsTitle = [
    { titleName: "Welcome to SHIGA", refType: introductionRef },
    {
      titleName: "www.shiga.io is provided as an informational resource",
      refType: InformationResourceRef,
    },
    {
      titleName: "Assumption of Risk",
      refType: AssumptionOfRiskRef,
    },
    {
      titleName: "Taxes",
      refType: TaxesRef,
    },
    {
      titleName: "The Services",
      refType: TheServicesRef,
    },
    {
      titleName: "Restrictions and Prohibited Material",
      refType: RestrictionRef,
    },
    {
      titleName: "Disclaimers and Liability Limitations",
      refType: DisclaimerRef,
    },
    { titleName: "Indemnity", refType: IndemnityRef },
    { titleName: "Dispute Resolution", refType: DisputeRef },
    {
      titleName: "Waiver of Injunction or other Equitable Relief",
      refType: WaiverInjunctionRef,
    },
    { titleName: "Termination", refType: TerminationRef },
    { titleName: "Children", refType: ChildrenRef },
    { titleName: "Severability", refType: SeverabilityRef },
    { titleName: "Assignment", refType: AssignmentRef },
    { titleName: "Entire Agreement", refType: AgreementRef },
  ];

  const scrolLWithUseRef = (incoming: MutableRefObject<HTMLDivElement>) => {
    setRefTitle(incoming.current.title);
    window.scrollTo({
      top: incoming.current.offsetTop - 100,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setRefTitle("Introduction");
    return;
  }, []);

  return (
    <div className="bg-[white] h-full w-full pb-[250px] md:pb-[350px]">
      <div className="w-full pt-[100px] pb-[60px] bg-[#E6E6FF99]/25">
        <h1 className="text-[36px] text-center font-ibm leading-[50px] tracking-normal font-bold md:text-[25px]">
          TERMS OF SERVICE{" "}
        </h1>
      </div>
      <div className="flex justify-center">
        <div className="max-w-[1300px] h-full pb-[50px]">
          <div className=" lg:px-6 xl:px-12 w-full h-full px-14 xlsm:px-3">
            <div className=" flex gap-[40px]">
              <div className="w-[35%] h-full md:hidden">
                <div className="sticky top-[100px] cursor-pointer">
                  <p className="text-[#2a3342] text-[20px] leading-[30px] font-[600] mt-[40px] tracking-normal">
                    In this section{" "}
                  </p>
                  <div className="mt-[15px]">
                    {termsTitle.map((item) => {
                      return (
                        <div key={item.titleName}>
                          <p
                            onClick={() =>
                              scrolLWithUseRef(
                                item.refType as unknown as MutableRefObject<HTMLDivElement>
                              )
                            }
                            className={
                              refTitle === item.titleName
                                ? `text-hbrand-500 text-[16px] bg-[#E6E6FF99]/25 py-4 px-4 leading-[150%] font-[600] tracking-normal mb-2`
                                : `text-[16px] leading-[150%] text-[#556987] py-1 tracking-normal mb-2`
                            }
                          >
                            {item.titleName}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="w-[65%] md:w-full h-full">
                <div className="w-full">
                  <div className="mt-[40px] flex items-center gap-[8px]">
                    <p className="text-[#556987] text-[14px] leading-[30px] font-[600]  tracking-normal">
                      By using the Shiga application and services you have
                      agreed to the terms detailed below. Kindly review
                      carefully.
                    </p>
                  </div>
                  <div className="mt-[20px] flex items-center gap-[8px]">
                    <div>
                      <img
                        src="/assets/calendar.svg"
                        alt="icon"
                        className="w-[20px] h-[20px]"
                      />
                    </div>
                    <p className="text-[#556987] text-[14px] leading-[30px] font-[600]  tracking-normal">
                      Last updated: {formattedDate}
                    </p>
                  </div>
                  <Introduction pageRef={introductionRef} />
                  <InformationResource pageRef={InformationResourceRef} />
                  <AssumptionOfRisk pageRef={AssumptionOfRiskRef} />
                  <Taxes pageRef={TaxesRef} />
                  <TheServices pageRef={TheServicesRef} />
                  <Restrictions pageRef={RestrictionRef} />
                  <Disclaimers pageRef={DisclaimerRef} />
                  <Indemnity pageRef={IndemnityRef} />
                  <DisputeResolution pageRef={DisputeRef} />
                  <WaiverInjunction pageRef={WaiverInjunctionRef} />
                  <Termination pageRef={TerminationRef} />
                  <Children pageRef={ChildrenRef} />
                  <SeverabilityB pageRef={SeverabilityRef} />
                  <Assignment pageRef={AssignmentRef} />
                  <Agreement pageRef={AgreementRef} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsOfService;
