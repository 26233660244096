import { FC } from "react";
import { Section } from "./blogDetails";


interface BlogDetailSectionProps {
  section: Section; // Ensure this matches the exported type from "."
}

const BlogDetailSection: FC<BlogDetailSectionProps> = ({ section }) => {
  return (
    <div className="col-span-2 mb-5">
      <div id="keyTakeaways" className="keyTakeaways bg-[#F9FAFB] px-12 pt-7 pb-9 rounded-2xl">
        <h4 className="font-satoshi text-htext-main text-[15.5px] leading-5 font-black mb-4 -ml-[12px]">Key takeaways</h4>
        <ul className="text-[15.5px] leading-6 font-normal text-htext-main list-disc space-y-5">
          <li>Stablecoins are the ideal solution for users looking to bridge traditional finance and blockchain due to their stability and global acceptance.</li>
          <li>Shiga simplifies the complex process of converting between fiat and stablecoins, offering a seamless experience across Ethereum, Tron, and Solana.</li>
          <li>The portaling feature allows users to easily move funds in and out of stablecoins, addressing a key pain point in the crypto ecosystem.</li>
          <li>Security and user experience are core pillars of Shiga’s design, making it accessible for a wide range of users.</li>
        </ul>
      </div>

      <div id="understandingStablecoins" className="space-y-5 mt-9">
        <h3 className="text-[28px] text-htext-main font-black leading-[35px]">Understanding Stablecoins: Bridging Fiat and Crypto</h3>
        <p className="text-[15.5px] leading-6 font-normal text-htext-main">Stablecoins are digital assets pegged to the value of traditional currencies like the US dollar. They offer the advantages of cryptocurrency—speed, transparency, and decentralization—without the volatility typically associated with other digital assets like Bitcoin or Ethereum.</p>
        <p className="text-[15.5px] leading-6 font-normal text-htext-main">USDT (Tether) and USDC (USD Coin) are among the most popular stablecoins, used widely across the world for everything from trading to remittances. But the challenge for many users is how to easily convert their traditional fiat currency into stablecoins and manage them across different blockchain networks.</p>
      </div>

      <div id="theChallenge" className="space-y-5 mt-9">
        <h3 className="text-[28px] text-htext-main font-black leading-[35px]">The Challenge: Moving from Fiat to Stablecoins</h3>
        <p className="text-[15.5px] leading-6 font-normal text-htext-main">For many users, getting from fiat to stablecoins is not as simple as it sounds. Traditional exchanges can be slow, costly, and complex, particularly for users who want to move funds across multiple blockchain networks. Adding to the challenge is the fragmentation of the blockchain ecosystem, where different networks like Ethereum, Tron, and Solana each have their own technical requirements for wallet generation and asset management.</p>
        <p className="text-[15.5px] leading-6 font-normal text-htext-main">This complexity often leaves users struggling to move their assets fluidly between fiat and stablecoins, hindering broader adoption.</p>
      </div>

      <div className="mt-9">
        <h3 className="text-[28px] text-htext-main font-black leading-[35px]">Shiga’s Seamless Portal: How It Works</h3>
        <p className="mt-5 text-[15.5px] leading-6 font-normal text-htext-main">Shiga steps in to address these challenges with its innovative portal that simplifies the process of portaling in and out of stablecoins across multiple blockchains. Let’s dive into how it works:</p>

        <div className="space-y-5 mt-9">
        <h6 className="font-satoshi text-[15.5px] leading-6 font-bold text-htext-main"> Wallet Generation Across Networks</h6>
        <p className="text-[15.5px] leading-6 font-normal text-htext-main">With Shiga, users can easily generate wallets for holding USDT and USDC on three major blockchain networks—EVM-compatible chains (like Ethereum), Tron, and Solana. This cross-chain support allows users to choose the network that best suits their needs, whether they’re focused on transaction speed, fees, or DeFi integration.</p>
        </div>

        <div className="space-y-5 mt-9">
        <h6 className="font-satoshi text-[15.5px] leading-6 font-old text-htext-main"> Portaling In: Converting Fiat to USDT/USDC</h6>
        <p className="text-[15.5px] leading-6 font-normal text-htext-main">The process of portaling in refers to converting traditional fiat currency into stablecoins like USDT or USDC. Shiga offers a streamlined, user-friendly experience that allows users to convert fiat to stablecoins in a few simple steps. Through trusted partnerships and integrations with payment providers, Shiga ensures fast, low-cost transactions with minimal friction.</p>
        </div>

        <div className="space-y-5 mt-9">
        <h6 className="font-satoshi text-[15.5px] leading-6 font-bold text-htext-main"> Portaling Out: Moving Stablecoins Back to Fiat</h6>
        <p className="text-[15.5px] leading-6 font-normal text-htext-main">When users are ready to convert their stablecoins back into fiat, Shiga’s portal makes it equally easy. Users can seamlessly portal out of their stablecoins, withdrawing funds into their bank accounts or other fiat-based platforms. This feature is particularly useful for users who need quick access to liquid funds without the hassle of traditional exchanges.</p>
        </div>
      </div>

      <div className="space-y-5 mt-9">
        <h3 className="text-[28px] text-htext-main font-black leading-[35px]">Security and User Experience in the Shiga Ecosystem</h3>
        <p className="text-[15.5px] leading-6 font-normal text-htext-main">Security is a top priority in the world of blockchain and cryptocurrency. Shiga integrates advanced security protocols and multi-signature wallets to ensure that user funds are protected at all times. The platform also places a strong emphasis on user experience, offering a clean, intuitive interface that caters to both beginners and experienced crypto users.</p>
        <p className="text-[15.5px] leading-6 font-normal text-htext-main">By removing the technical hurdles typically associated with cross-chain asset management, Shiga makes it easier than ever to interact with the world of stablecoins.</p>
      </div>

      <div className="space-y-5 mt-9">
        <h3 className="text-[28px] text-htext-main font-black leading-[35px]">Why Shiga is a Game Changer for Crypto Users</h3>
        <p className="text-[15.5px] leading-6 font-normal text-htext-main">Shiga’s seamless portal is a game changer for several reasons:</p>
        <ul className="text-[15.5px] leading-6 font-normal text-htext-main list-disc space-y-2.5 px-[20px]">
           <li>Cross-Chain Compatibility: With support for Ethereum, Tron, and Solana, users are not locked into a single network.</li>
           <li>Simplicity: Shiga drastically reduces the steps required to move between fiat and stablecoins.</li>
           <li>Speed: By leveraging strategic partnerships, Shiga offers low-cost, fast transactions, making it ideal for both retail users and businesses.</li>
           <li>Security: Shiga’s strong focus on user safety ensures peace of mind for anyone interacting with the platform.</li>
        </ul>
      </div>
    </div>
  );
};

export default BlogDetailSection;
