type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function Assignment({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Assignment">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>14.</span> Assignment
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        The Agreement may be assigned without your prior consent to us or our
        respective successors and assigns in the interest of any business
        associated with our services. You shall not assign or transfer any
        rights or obligations under this Agreement without our prior written
        consent.
      </p>
    </div>
  );
}

export default Assignment;
