import { FC } from "react";
import { Section } from "../../components/blog/blogDetails";
import { Link } from "react-router-dom";

type sideProps = {
  changeSelectedSection: (section: Section) => void;
  section: string;
};

const staticSection = {
  name: "Key takeaways",
  value: "keyTakeaways",
};

const socialLinks = [
  {
    name: "shiga link tree",
    src: "/assets/links.svg",
    route: "#",
  },
  {
    name: "shiga twitter link",
    src: "/assets/twitter.svg",
    route: "#",
  },
  {
    name: "shiga facebook link",
    src: "/assets/fb.svg",
    route: "#",
  },
  {
    name: "shiga linkedIn link",
    src: "/assets/linkedIn.svg",
    route: "#",
  },
]

// Function to Generate Sidebar Items
const generateSideBarItems = (dynamicData: Array<{ name: string; value: string }>) => {
  return [
    staticSection, // Static "Key takeaways" section
    ...dynamicData.map((item) => ({
      name: item.name,
      value: item.value,
    })),
  ];
};

const dynamicData = [
  { name: "Understanding Stablecoins: Bridging Fiat and Crypto", value: "understandingStablecoins" },
  { name: "The Challenge: Moving from Fiat to Stablecoins", value: "theChallenge" },
  { name: "Shiga’s Seamless Portal: How It Works", value: "shigasSeamlessPortal" },
  { name: "Security and User Experience in the Shiga Ecosystem", value: "securityAndUserExperience" },
  { name: "Why Shiga is a Game Changer for Crypto Users", value: "whyShigaIsAGameChanger" },
];
export const sideBarItems = generateSideBarItems(dynamicData);


const Sidebar: FC<sideProps> = ({ changeSelectedSection, section }) => {
  const handleClick = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    const container = document.querySelector(".blog-details-content"); // Scrollable container
  
    if (element && container) {
      const containerTop = container.getBoundingClientRect().top; // Container's top position
      const elementTop = element.getBoundingClientRect().top; // Element's top position
      const offset = elementTop - containerTop; // Position relative to container
  
      // Use a slight delay to ensure accurate scrolling
      setTimeout(() => {
        (container as HTMLElement).scrollTo({
          top: offset - 64, // Adjust for fixed header or padding
          behavior: "smooth",
        });
      }, 0); // Delay of 0 ensures rendering is complete
    }
  
    changeSelectedSection(sectionId); // Update the selected section
  };
  

  return (
    
    <div className="flex flex-col mb-6">
      <div className="pb-8 flex flex-col space-y-2">
      {
        sideBarItems.map((item) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            key={item.name}
            onClick={() => {
              if (item.value !== section) {
                handleClick(item.value); // Call handleClick only if not selected
              }
            }}
            role="button"
            tabIndex={item.value === section ? -1 : 0}
            className={`cursor-pointer text-[13.5px] font-normal py-[8px] px-[9.5px] ${
              item.value === section
                ? "text-htext-main font-semibold border-l-2 border-l-htext-main"
                : "text-htext-subdued"
            }`}
          >
            {item.name}
          </a>
        ))
      }
      </div>
     
     <div className="side-icons border-t border-t-[#E4E7EC] pt-8 flex items-center gap-x-2">
      {socialLinks.map((link, index) => (
        <Link to={link.route} key={index}>
          <img className="w-[38px] hover:scale-95" src={link.src} alt={link.name} />
        </Link>
  
      ))}
     </div>
    </div>
  )
}

export default Sidebar;
