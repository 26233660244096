type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function Introduction({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Introduction">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>1.</span> Introduction
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Shiga Digital Limited (“<span className="font-[600]">Shiga</span>” “
        <span className="font-[600]">SDL</span>” “
        <span className="font-[600]">we</span>”, “
        <span className="font-[600]">us</span>”, “
        <span className="font-[600]">our</span>”) is aware that when you use our
        services, you’re trusting us with your information. We understand the
        importance of this responsibility and we are committed to safeguarding
        the privacy of and ensuring you have control of the personal information
        that is provided to us or collected by us during the course of our
        business or from visits to our website.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        This Privacy policy explains what information we collect, why and how we
        may collect and use any personal information that we obtain about you
        and your rights in relation to that information. In addition, it sets
        out how you can update, manage, export, and delete your information and
        how to contact us if you have any questions about this Privacy Policy or
        how we handle your personal information.
      </p>
    </div>
  );
}

export default Introduction;
