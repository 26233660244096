type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function StorateOrRetentionPeriod({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Storage/Retention Period">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>7.</span> Storage/Retention Period
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Following termination or deactivation of your user account, or at your
        request (where applicable), Shiga will retain your profile for a
        commercially reasonable time, and for as long as we have a valid purpose
        to do so, or as required by the law. Shiga will retain your information
        for the purpose of complying with its legal and audit obligations, and
        for backup and archival purposes.
      </p>
    </div>
  );
}

export default StorateOrRetentionPeriod;
