type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function Children({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Children">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>12.</span> Children
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Our services are intended for general audiences and are not directed at
        children. To use the Services, you must legally be able to enter into
        the Agreement. We do not knowingly collect personal information (as
        defined by the Act) from children. If you are a parent or guardian and
        believe we have collected personal information in violation of the law,
        please contact us at ... and we will remove the personal information in
        accordance with the Act.
      </p>
    </div>
  );
}

export default Children;
