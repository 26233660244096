type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function ThirdParty({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Third-Party Sites">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>6.</span> Third-Party Sites
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Our Websites may contain links to other sites which are controlled by
        third parties. You should review these other sites’ privacy policies. We
        do not accept any responsibility for their use of your personal
        information.
      </p>
    </div>
  );
}

export default ThirdParty;
