import { FC, useState } from "react";
import {
  arrowRightGraySVG,
  homeIcon,
  minus,
  plus,
  step1,
  step2,
  step3,
  stepMob1,
  stepMob2,
  stepMob3,
} from "../../common/svg";
import { Link } from "react-router-dom";
import { useMobile } from "../../utlis";

const CaseStudyDoBusiness: FC = () => {
  const isMobile = useMobile();

  const [openFAQIndex, setOpenFAQIndex] = useState<{
    section: number | null;
    index: number | null;
  }>({ section: null, index: null });

  // Toggle function to handle opening/closing of the FAQs
  const toggleFAQ = (section: number, index: number) => {
    // If the same FAQ is clicked, close it; otherwise, open the new one
    if (openFAQIndex.section === section && openFAQIndex.index === index) {
      setOpenFAQIndex({ section: null, index: null });
    } else {
      setOpenFAQIndex({ section, index });
    }
  };

  const faqContent1 = [
    {
      question: "What is Shiga?",
      answer:
        "Shiga Digital is leading the way in creating a cutting-edge blockchain-based bank with a special focus on Pan Africa. Say goodbye to traditional finance and hello to the new era of financial empowerment for Pan-Africa! We're building the core components to redefine the financial landscape, providing liquidity through stablecoins, seamless payments, and access to real-world assets. Our self-custodial wallets will empower you to take control of your assets like never before. Shiga is paving the way for the next wave of enterprises and individuals seeking innovative financial services. Get ready to join the movement!",
    },
    {
      question: "Why did we build Shiga?",
      answer:
        "Blockchain-based finance has the potential to transform access to finance, with a handful of companies already leading this revolution. However, to make this work for the benefit of all and truly achieve scale, we need to fix the disconnect between the current system and the immaturity of the new system. Shiga is driving towards a future where both systems coexist harmoniously, bringing the best of both worlds to businesses, banks, and payment providers.",
    },
    {
      question: "Is Shiga regulated?",
      answer:
        "Shiga's VASP is registered in the Czech Republic, which enables Shiga to engage in digital asset services in Europe. Specifically, this includes the intermediation of trade and services, wholesale and retail trade, software provision, information technology consultancy, data processing, hosting and related activities, web portals, and virtual asset services.",
    },
    {
      question: "How is shiga regulated out of europe?",
      answer:
        "Outside of Europe, Shiga Digital, Inc. is a Delaware-based C Corporation Leveraging regulated entities to provision subsequent gateways in supported regions.",
    },
  ];

  const faqContent2 = [
    {
      question: "What exactly are stablecoins?",
      answer:
        "Stablecoins are digital currencies designed to provide stability. They are pegged to stable assets like the US dollar and backed by equivalent US Treasuries reserves. Issued by regulated entities such as Circle or Coinbase, stablecoins combine cryptocurrencies' advantages with traditional currencies' stability.",
    },
    {
      question: "What is a self-custody wallet? ",
      answer:
        " Non-custodial means that you're always in control. Unlike traditional banks, Shiga never has unilateral access to your funds. There’s no rehypothecation without your consent—your assets are yours. Our platform ensures complete transparency and control, setting a new financial freedom and security standard.",
    },
    {
      question: "How long does it take for transactions to process?",
      answer:
        "We aim to make every Shiga Powered transaction feel instant. Most portal transactions are processed in less than five minutes using your Shiga portals. Shiga uses blockchain technology to process all orders instantly (sending them for clearing immediately). Transaction times may vary depending on local banking processing times.",
    },
    {
      question: "Does Shiga have a mobile application?",
      answer: "Coming soon 😃",
    },
  ];

  return (
    <div className="useCase-wrapper relative">
      <section className="useCases-container w-full h-full bg-white">
        <div
          className={`business-hero relative flex flex-col items-center justify-start bg-white pt-12 pb-8 z-10 w-full ${
            isMobile ? "h-[605px]" : "h-[900px]"
          } -mt-16`}
        >
          {!isMobile && (
            <div className="w-full flex items-center justify-start px-[118px] mt-[40px] pt-[60px]">
              <Link to="/">
                <span
                  className=""
                  dangerouslySetInnerHTML={{ __html: homeIcon }}
                />
              </Link>
              <span
                className="px-4"
                dangerouslySetInnerHTML={{ __html: arrowRightGraySVG }}
              />
              <Link to="/our-case-studies">
                <p className="text-htext-label text-[12.5px] font-ibm font-medium">
                  Case study
                </p>
              </Link>
              <span
                className="px-4"
                dangerouslySetInnerHTML={{ __html: arrowRightGraySVG }}
              />
              <p className="text-htext-disabled text-[12.5px] font-ibm font-medium">
                Do Business in Africa without an African Bank Account
              </p>
            </div>
          )}

          <div
            className={`flex flex-col items-center justify-center ${
              isMobile ? "w-full px-[24px] pt-[4.5rem]" : "w-[800px] pt-[6rem]"
            }`}
          >
            <h6
              className={`font-ibm font-medium ${
                isMobile ? "text-[11px]" : "text-[10.5px]"
              } w-fit px-2 py-[0.5px] rounded-2xl text-hgray-700 bg-hgray-50 border border-hgray-200`}
            >
              CASE STUDY
            </h6>
            <h1
              className={`text-center text-htext-main ${
                isMobile
                  ? "text-[30.5px] leading-[38px] text-center"
                  : "text-[44px] leading-[58px]"
              } font-satoshi font-black mt-[25px]`}
            >
              How Onyx use Shiga to Simplify Cross-Border Payments Without an
              African Bank Account
            </h1>

            <img
              className="mt-[52px]"
              src="/assets/case1.png"
              alt="Case study 1 pics"
            />
          </div>
        </div>

        <div
          className={`cases relative bg-white ${
            isMobile ? "px-[24px]" : "px-[185px]"
          }`}
        >
          <div
            className={`pt-[44px] ${
              isMobile ? "w-full pb-[28px]" : "w-[810px] pb-[33px]"
            } flex flex-col items-center justify-center mx-auto`}
          >
            <div
              className={`flex ${
                isMobile ? "flex-col pb-[36px]" : "pb-[88px]"
              } items-start justify-between w-full`}
            >
              <h3
                className={`${
                  isMobile ? "text-[28px]" : "text-[36px]"
                } text-start text-htext-main font-satoshi font-black -tracking-[0.8px]`}
              >
                The Challenge
              </h3>
              <div className={`${isMobile ? "w-full" : "w-[61%]"}`}>
                <p
                  className={`text-start text-htext-label ${
                    isMobile ? "text-[17px] mt-5" : "text-[15.5px]"
                  } font-ibm font-normal`}
                >
                  Onyx, a global logistics company with operations in Europe,
                  America, and Africa, faces several challenges managing
                  cross-border payments in Africa:
                </p>
                <div className="rounded-2xl bg-hgray-50 mt-6 w-full px-[24px] py-[24px]">
                  <h6
                    className={`text-start text-htext-subdued ${
                      isMobile ? "text-[13.5px]" : "text-[12.5px]"
                    } font-satoshi font-bold`}
                  >
                    THE CONCERNS
                  </h6>
                  <p
                    className={`bullet relative flex items-center text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6 mt-5`}
                  >
                    Rapid currency depreciation across African countries makes
                    it difficult to manage stable finances.
                  </p>
                  <p
                    className={`bullet relative flex items-center text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6 mt-5`}
                  >
                    Moving money between multiple countries and entities is
                    slow, expensive, and risky.
                  </p>
                  <p
                    className={`bullet relative flex items-center text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6 mt-5`}
                  >
                    Businesses often lose significant revenue due to fluctuating
                    exchange rates and fees for converting local currencies to
                    global ones.
                  </p>
                  <p
                    className={`bullet relative flex items-center text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6 mt-5`}
                  >
                    Managing multiple bank accounts in different countries can
                    be time-consuming and creates unnecessary complexity.
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`flex ${
                isMobile
                  ? "flex-col pb-[36px] pt-[33px]"
                  : "pb-[88px] pt-[44px]"
              } items-start justify-between w-full border-t border-t-hgray-200`}
            >
              <h3
                className={`${
                  isMobile ? "text-[28px]" : "text-[36px]"
                } text-start text-htext-main font-satoshi font-black -tracking-[0.8px]`}
              >
                The Solution
              </h3>
              <div className={`${isMobile ? "w-full" : "w-[61%]"}`}>
                <p
                  className={`text-start text-htext-label ${
                    isMobile ? "text-[17px] mt-5" : "text-[15.5px]"
                  } font-ibm font-normal`}
                >
                  Onyx partners with Shiga to streamline their cross-border
                  payments. By using Shiga’s virtual multi-currency accounts—
                  <Link
                    to="/"
                    className="text-hbrand-600 hover:text-hbrand-700 underline"
                  >
                    portal in/out
                  </Link>
                  , Onyx is able to:
                </p>
                <div className="rounded-2xl bg-hgray-50 mt-6 w-full px-[24px] py-[24px]">
                  <p
                    className={`bullet relative flex items-center text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6`}
                  >
                    Receive virtual Naira, EUR, USD, and GBP accounts, allowing
                    them to easily collect payments in Nigeria and other
                    markets.
                  </p>
                  <p
                    className={`bullet relative flex items-center text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6 mt-5`}
                  >
                    Automatically convert Naira and other local currencies into
                    their preferred global currency and transfer the funds to
                    their main traditional bank account.
                  </p>
                  <p
                    className={`bullet relative flex items-center text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6 mt-5`}
                  >
                    Avoid exposure to volatile local currencies, reducing the
                    risk of losses due to depreciation.
                  </p>
                  <p
                    className={`bullet relative flex items-center text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6 mt-5`}
                  >
                    Eliminate the need for intermediaries, FX agents, or
                    managing multiple local bank accounts, saving time and
                    money.
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`flex ${
                isMobile
                  ? "flex-col pb-[36px] pt-[33px]"
                  : "pt-[44px] pb-[55px]"
              } items-start justify-between w-full border-t border-t-hgray-200`}
            >
              <h3
                className={`${
                  isMobile ? "text-[28px]" : "text-[36px]"
                } text-start text-htext-main font-satoshi font-black -tracking-[0.8px]`}
              >
                The Results
              </h3>
              <div className={`${isMobile ? "w-full" : "w-[61%]"}`}>
                <p
                  className={`text-start text-htext-label ${
                    isMobile ? "text-[17px] mt-5" : "text-[15.5px]"
                  } font-ibm font-normal`}
                >
                  With Shiga’s solution, Onyx experiences:
                </p>
                <div className="rounded-2xl bg-hgray-50 mt-6 w-full px-[24px] py-[24px]">
                  <p
                    className={`bullet relative  text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6`}
                  >
                    <b className="font-semibold">Faster Transactions:</b>{" "}
                    Payments were instantly converted and transferred to their
                    global account, eliminating delays.
                  </p>
                  <p
                    className={`bullet relative  text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6 mt-5`}
                  >
                    <b className="font-semibold">Cost Efficiency:</b> Onyx saved
                    money by cutting out unnecessary intermediaries and reducing
                    currency conversion fees.
                  </p>
                  <p
                    className={`bullet relative text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6 mt-5`}
                  >
                    <b className="font-semibold">Lower Risk:</b> They no longer
                    had to worry about currency volatility affecting their
                    bottom line.
                  </p>
                  <p
                    className={`bullet relative text-start text-htext-label ${
                      isMobile ? "text-[15px]" : "text-[14px]"
                    } font-ibm font-normal ml-6 mt-5`}
                  >
                    <b className="font-semibold">Simplified Operations:</b> Onyx
                    significantly reduced the complexity of managing multiple
                    accounts and reconciliations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-hear relative bg-white">
          <div
            className={`flex flex-col items-center ${
              isMobile
                ? "w-full px-[24px] pt-[58px] pb-[61px]"
                : "px-[148px] py-[80px]"
            }`}
          >
            <p className="font-ibm font-medium text-[12px] text-center text-hgray-700 border border-hgray-200 bg-hgray-50 rounded-2xl px-2 py-0.5 w-fit">
              HEAR FROM SHIGA CUSTOMERS
            </p>

            <div
              className={`${
                isMobile ? "w-[91%] mt-[35px]" : "w-[760px] mt-[40px]"
              }`}
            >
              <h2
                className={`hear-text font-satoshi font-black text-hgray-950 w-full text-[43.5px] gap-x-3 text-center flex flex-wrap justify-center ${
                  isMobile ? "items-end text-center" : "items-center"
                }`}
              >
                Preferred by <p>individuals</p>
                <div className="tool-cont relative flex flex-col items-center">
                  <img
                    className={`cursor-pointer ${
                      isMobile ? "w-[62px]" : "w-[72px]"
                    }`}
                    src="/assets/user1.svg"
                    alt="first user"
                  />
                  <span
                    className={`tooltip-text absolute left-1/2 transform -bottom-12 text-white text-[12.5px] font-normal font-ibm rounded ${
                      isMobile
                        ? "px-1 -translate-x-[84%]"
                        : "px-2 -translate-x-1/2"
                    } py-1 opacity-0 transition-opacity duration-300 pointer-events-none`}
                  >
                    In a foreign exchange market (formerly) dominated by shady
                    and untraceable dealers, Shiga has been a breath of fresh
                    air. SUPER-quick, reliable and traceable payments from a
                    single legitimate source.
                  </span>
                </div>
                <p>and </p>
                <p className={`${isMobile ? "" : "-ml-[111px]"}`}>
                  {" "}
                  businesses
                </p>
                <div className="tool-cont relative flex flex-col items-center">
                  <img
                    className={`cursor-pointer ${
                      isMobile ? "w-[62px]" : "w-[72px]"
                    }`}
                    src="/assets/user2.svg"
                    alt="second user"
                  />
                  <span
                    className={`tooltip-text absolute left-1/2 transform -bottom-12 text-white text-[12.5px] font-normal font-ibm rounded ${
                      isMobile
                        ? "px-1 -translate-x-[84%]"
                        : "px-2 -translate-x-1/2"
                    } py-1 opacity-0 transition-opacity duration-300 pointer-events-none`}
                  >
                    Shiga has been great at supporting our operational FX needs,
                    including payroll, servicing our events, and more. The
                    process is swift, reliable and smooth.
                  </span>
                </div>
                <p>that</p> <p>care </p> <p>about </p> <p>their </p>
              </h2>

              <h2 className="hear-text font-satoshi font-black text-hgray-950 w-full text-[43.5px] text-center flex flex-wrap justify-center">
                finances and
                <div className="tool-cont relative flex flex-col items-center">
                  <img
                    className={`mx-2 cursor-pointer ${
                      isMobile ? "w-[62px]" : "w-[72px] mt-[10px]"
                    }`}
                    src="/assets/user3.svg"
                    alt="third user"
                  />
                  <span
                    className={`tooltip-text absolute left-1/2 transform -bottom-12 text-white text-[12.5px] font-normal font-ibm rounded ${
                      isMobile
                        ? "px-1 -translate-x-[84%]"
                        : "px-2 -translate-x-1/2"
                    } py-1 opacity-0 transition-opacity duration-300 pointer-events-none`}
                  >
                    Shiga has been a game-changer for managing our liquidity as
                    a business, especially in the volatile FX market. Relying on
                    a system that essentially automates monthly payments has
                    given us peace of mind.
                  </span>
                </div>
                their flexibility
              </h2>
            </div>

            <img
              className="-ml-[9.2rem] mt-[40px]"
              src="/assets/pointer.svg"
              alt="try to hover"
            />
          </div>
        </div>
      </section>

      <section className="useCases-container w-full h-full bg-hbrand-25">
        <div className="3steps three-steps relative bg-hbrand-25">
          <div
            className={`3steps-cont ${
              isMobile
                ? "px-[40px] pt-[60px] pb-[69px]"
                : "px-[148px] py-[88px]"
            } w-full flex flex-col items-center`}
          >
            <h2
              className={`font-satoshi font-black text-hgray-950 ${
                isMobile ? "text-[31px] leading-[38px]" : "text-[43.5px]"
              } text-center`}
            >
              Get started in 3 simple steps
            </h2>

            <div
              className={`flex ${
                isMobile ? "flex-col gap-y-12" : "gap-x-6"
              } items-start justify-center w-full mt-[49px]`}
            >
              <div
                className={`flex flex-col items-center justify-center text-center gap-y-3.5 ${
                  isMobile ? "w-full" : "w-[340px]"
                }`}
              >
                <span
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: isMobile ? stepMob1 : step1,
                  }}
                />
                <h6 className="steps-text1 mt-3 text-htext-main text-[18px] font-satoshi font-black">
                  Create an account
                </h6>
                <p className="steps-text2 text-htext-subdued text-[16.5px] font-ibm font-normal">
                  Sign up with your email and start your journey with Shiga
                </p>
              </div>

              <div
                className={`flex flex-col items-center justify-center text-center gap-y-3.5 ${
                  isMobile ? "w-full" : "w-[340px]"
                }`}
              >
                <span
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: isMobile ? stepMob2 : step2,
                  }}
                />
                <h6 className="steps-text1 mt-3 text-htext-main text-[18px] font-satoshi font-black">
                  Verify your business
                </h6>
                <p className="steps-text2 text-htext-subdued text-[16.5px] font-ibm font-normal">
                  Complete a quick KYC process to unlock full functionality.
                </p>
              </div>

              <div
                className={`flex flex-col items-center justify-center text-center gap-y-3.5 ${
                  isMobile ? "w-full" : "w-[340px]"
                }`}
              >
                <span
                  className=""
                  dangerouslySetInnerHTML={{
                    __html: isMobile ? stepMob3 : step3,
                  }}
                />
                <h6 className="steps-text1 mt-3 text-htext-main text-[18px] font-satoshi font-black">
                  Start Using Shiga
                </h6>
                <p className="steps-text2 text-htext-subdued text-[16.5px] font-ibm font-normal">
                  Send your stablecoins, make your swaps, and enjoy easy
                  withdrawals.
                </p>
              </div>
            </div>
            <a href="https://business.shiga.io/signup">
              <button className="px-[70px] py-[10.5px] mt-[48px] border rounded-lg bg-hbrand-600 border-hbrand-600 hover:bg-hbrand-700 hover:border-hbrand-700 text-white text-[14.5px] font-ibm font-semibold shadow-s-light">
                Create your account
              </button>
            </a>
          </div>
        </div>
      </section>

      <section className="useCases-container w-full h-full bg-white">
        <div className="home-faq relative bg-white">
          <div
            className={`faq-cont ${
              isMobile
                ? "px-[24px] pt-[85px] pb-[23rem]"
                : "pt-[88px] pb-[17rem] px-[148px]"
            }`}
          >
            <h2 className="faq-header font-satoshi font-black text-hgray-950 text-[43.5px] text-start">
              FAQ
            </h2>
            <div
              className={`w-full flex ${
                isMobile ? "flex-col" : ""
              } items-start justify-between flex-wrap mt-6`}
            >
              {/* First Column of FAQs */}
              <div
                className={`flex flex-col items-start justify-center ${
                  isMobile ? "w-full" : "w-[48%]"
                }`}
              >
                {faqContent1.map((faq, index) => (
                  <div
                    className={`faq-card w-full cursor-pointer ${
                      index === 0
                        ? "border-y border-y-hgray-200"
                        : "border-b border-b-hgray-200"
                    } py-5`}
                    key={index}
                    onClick={() => toggleFAQ(1, index)}
                  >
                    <div className="flex items-center justify-between">
                      <h3
                        className={`font-ibm font-bold text-[15.5px] text-htext-main ${
                          isMobile ? "pr-1" : ""
                        }`}
                      >
                        {faq.question}
                      </h3>
                      <span
                        className=""
                        dangerouslySetInnerHTML={{
                          __html:
                            openFAQIndex.section === 1 &&
                            openFAQIndex.index === index
                              ? minus
                              : plus,
                        }}
                      />
                    </div>
                    {openFAQIndex.section === 1 &&
                      openFAQIndex.index === index && (
                        <div className="toggle-content mt-2.5">
                          <p className="font-ibm font-normal text-[14.5px] text-htext-label w-[90%]">
                            {faq.answer}
                          </p>
                        </div>
                      )}
                  </div>
                ))}
              </div>

              {/* Second Column of FAQs */}
              <div
                className={`flex flex-col items-start justify-center ${
                  isMobile ? "w-full" : "w-[48%]"
                }`}
              >
                {faqContent2.map((faq, index) => (
                  <div
                    className={`faq-card w-full cursor-pointer ${
                      isMobile && index === 0
                        ? "border-b border-b-hgray-200"
                        : !isMobile && index === 0
                        ? "border-y border-y-hgray-200"
                        : "border-b border-b-hgray-200"
                    } py-5`}
                    key={index}
                    onClick={() => toggleFAQ(2, index)}
                  >
                    <div className="flex items-center justify-between">
                      <h3 className="font-ibm font-bold text-[15.5px] text-htext-main">
                        {faq.question}
                      </h3>
                      <span
                        className=""
                        dangerouslySetInnerHTML={{
                          __html:
                            openFAQIndex.section === 2 &&
                            openFAQIndex.index === index
                              ? minus
                              : plus,
                        }}
                      />
                    </div>
                    {openFAQIndex.section === 2 &&
                      openFAQIndex.index === index && (
                        <div className="toggle-content mt-2.5">
                          <p className="font-ibm font-normal text-[14.5px] text-htext-label w-[90%]">
                            {faq.answer}
                          </p>
                        </div>
                      )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CaseStudyDoBusiness;
