type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function HowWeTransferData({ pageRef }: IPageRef) {
  return (
    <div
      className="mt-[25px]"
      ref={pageRef}
      title="How we transfer, store and protect your data"
    >
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>5.</span> How we transfer, store and protect your data?
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        Shiga cares about the security and safeguarding of your information. We
        use appropriate business systems and procedures to protect and safeguard
        your personal data. We also use security procedures and technical and
        physical restrictions for accessing and using the personal data on our
        servers. Only authorized personnel are permitted to access personal data
        in the course of their work.
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        To protect your privacy and security, we take reasonable steps to verify
        your identity before granting you access to our platform. You are 5
        responsible for maintaining your account information and password
        secrecy (where required).
      </p>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        We will only retain your personal information for as long as is
        necessary for the purpose for which it was collected, including for the
        purposes of providing our services, complying with any legal,
        regulatory, accounting, investigative or reporting requirements.
      </p>
    </div>
  );
}

export default HowWeTransferData;
