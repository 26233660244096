import { FC, MouseEvent } from "react";
import { useMobile } from "../../utlis";
import { useNavigate } from "react-router-dom";


const NotFound: FC = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  const handleNavigate = (e: MouseEvent<HTMLButtonElement>, route: string) => {
    e.preventDefault();
    navigate(route);
  }

  return (
    <div className="notFound-wrapper relative w-full h-screen bg-hbrand-25">
       <section className="notFound-container yolo w-full h-full bg-hbrand-25">
           <div className={`${isMobile ? "w-full px-11" : "w-[550px]"} h-full flex flex-col items-center justify-center m-auto gap-y-8`}>
            <img className={`${isMobile ? "w-[210px]" : ""}`} src="/assets/404.svg" alt="404 Notfound page" />

            <h2 className={`${isMobile ? "text-[31.5px] -tracking-[0.64px] leading-[36px]" : "text-[46px] -tracking-[0.96px] leading-[56px]"} mt-2 text-hgray-950  text-center font-satoshi font-black`}>Hmm, this page doesn’t exist</h2>

            <p className={`${isMobile ? " text-[15.5px] leading-[23px]" : " text-[18px] leading-[28px]"} text-htext-label text-center font-ibm font-normal`}>Sorry, the page you’re looking for isn’t here. This can happen because of typos in the web address or outdated links.</p>

            <button onClick={(e) => handleNavigate(e, "/")} 
            className={`${isMobile ? "w-full py-2.5 text-[15.5px]" : "py-3.5 text-[16.5px]"} px-[90px] bg-hbrand-600 border border-hbrand-600 hover:bg-hbrand-700 hover:border-hbrand-700 rounded-lg shadow-s-light text-white text-center font-ibm font-semibold`}>Go to homepage</button>
           </div>
       </section>
    </div>
  );
};

export default NotFound;
