import { FC, MouseEvent } from "react";
import { blogSearch, dotSVG } from "../../common/svg";
import { useNavigate } from "react-router-dom";
import { useMobile } from "../../utlis";

const cardData = [
  {
    cardIcon: "/assets/fencing-sword.svg",
    cardHeader: "Solana vs. Ethereum vs. Tron",
    cardTopic: "Solana vs. Ethereum vs. Tron: Which Network is Best for Stablecoin Users?",
    cardTag: "Shiga Basics",
    cardReadTime: "3 min read"
  },
  {
    cardIcon: "/assets/business-analysis.svg",
    cardHeader: "Business in Africa without a Local Bank",
    cardTopic: "Do Business in Africa without an African Bank Account",
    cardTag: "Insights",
    cardReadTime: "4 min read"
  },
  {
    cardIcon: "/assets/governance-token.svg",
    cardHeader: "What are Stablecoins?",
    cardTopic: "What are Stablecoins and why are they so powerful?",
    cardTag: "Insights",
    cardReadTime: "5 min read"
  },
  {
    cardIcon: "/assets/crypto-exchange.svg",
    cardHeader: "Wallet Security Best Practices",
    cardTopic: "Why Hold Physical Dollars When You Can Hold Digital Dollars?",
    cardTag: "Shiga Basics",
    cardReadTime: "5 min read"
  },
  {
    cardIcon: "/assets/swapping.svg",
    cardHeader: "Crypto Payment made Easy",
    cardTopic: "Accept Crypto as a Payment Method without knowing ANYTHING about Crypto",
    cardTag: "Shiga Basics",
    cardReadTime: "3 min read"
  },
  {
    cardIcon: "/assets/blockchain-network.svg",
    cardHeader: "Blockchain Tokenisation",
    cardTopic: "Tokenisation: Is the next potentially life-changing real-life use-case for the blockchain?",
    cardTag: "Insights",
    cardReadTime: "4 min read"
  },
  {
    cardIcon: "/assets/crypto-wallets.svg",
    cardHeader: "Blockchain Wallets Explained",
    cardTopic: "Wallets Explained: The engine behind the blockchain. What are they and how do they work?",
    cardTag: "Insights",
    cardReadTime: "3 min read"
  },
]

const colors = ["#3885F3", "#FEEAE7", "#1C1D1D", "#C1DCFF", "#F8F5F3", "#EEE8FF", "#E0E9D1"];


const Blog: FC = () => {
  const navigate = useNavigate();
  const isMobile = useMobile();

  const handleNavigate = (e: MouseEvent<HTMLDivElement>, route: string) => {
    e.preventDefault();
    navigate(route);
  }


  return (
    <section className="blogs-container w-full h-full bg-white">
    <div className={`blogs-wrapper relative pt-[2rem] h-full w-full bg-white ${isMobile ? " pb-[22rem]" : "px-[148px]  pb-[20rem]"}`}>
        <div className={`blogs-hero relative flex flex-col items-center justify-start pt-12 z-10 w-full ${isMobile ? "h-fit pb-[30px]" : "pb-[88px]"} -mt-14`}>
        <div className={`flex flex-col items-center justify-center ${isMobile ? "w-full px-[24px] pt-[2.5rem]" : "w-[800px] pt-[6rem]"}`}>
          <h1 className={`text-center text-hgray-950 ${isMobile ? "text-[31.5px] leading-[38px] text-center" : "text-[46px] leading-[60px] tracking-[-0.96px]"} font-satoshi font-black`}>
          The Shiga Blog</h1>
        <h6 className={`font-ibm font-normal ${isMobile ? "text-[17px] text-center" : "text-[19px]"} w-fit text-htext-label mt-[24px]`}>
        Blockchain insights and more, methodologies, trends and expert views.
            </h6>
        </div>
      </div>

      <div className="blogs-container py-[22px]">
         <div className={`container-header w-full flex ${isMobile ? "flex-col px-[24px] items-start gap-y-3" : "items-center justify-between"}`}>
           <h5 className={`font-semibold text-hprimary-900 ${isMobile ? "text-[17px]" : "text-[22px]"}`}>All blog posts</h5>
           <span className="cursor-pointer" dangerouslySetInnerHTML={{ __html: blogSearch }} />
         </div>

         <div className={`container-body mt-[32px] w-full flex ${isMobile ? "flex-col px-[24px]" : "gap-x-8 "} items-start justify-between flex-wrap gap-y-14`}>
          {cardData.map((card, index) => {
            const bgColor = colors[index % colors.length];
            const textColor = bgColor === "#3885F3" || bgColor === "#1C1D1D" ? "text-white" : "text-hprimary-900";
            const cardTop = bgColor === "#3885F3" || bgColor === "#1C1D1D" ? "card-top" : "card-top2";

            return (
              <div  className={`body-card flex flex-col ${isMobile ? "w-full" : "w-[31%]"} h-fit pb-2 hover:shadow-s-thick cursor-pointer rounded-lg hover:scale-95`} key={index} onClick={(e) => handleNavigate(e, `/blog/${index}`)}>
                  <div className={`${cardTop} relative rounded-lg w-full h-[230px] flex flex-col items-center justify-center ${card.cardHeader === "Solana vs. Ethereum vs. Tron" ? "px-[50px]" : "px-[40px]"} py-[32px]`} style={{ backgroundColor: bgColor }}>
                      <img src={card.cardIcon} alt={`${card.cardHeader} illustration`} />
                      <h2 className={`${textColor} text-[27.5px] font-bold leading-9 text-center mt-4 break-before-all`}>{card.cardHeader}</h2>
                  </div>
    
                  <div className="card-bottom mt-4">
                    <h2 className={`text-hprimary-900 font-satoshi font-bold ${isMobile ? "text-[19px] leading-6 " : "text-[23px] leading-8 "} pr-[13px]`}>{card.cardTopic}</h2>
                    <div className="bottom-read text-htext-disabled text-[13px] font-normal flex items-center mt-2">
                      <p>{card.cardTag}</p>
                      <span className="mx-[8px]" dangerouslySetInnerHTML={{ __html: dotSVG }} />
                      <p>{card.cardReadTime}</p>
                    </div>
                  </div>
              </div>
              )})}
         </div>
      </div>
    </div>
    </section>
  );
};

export default Blog;
