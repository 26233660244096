type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function Indemnity({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Indemnity">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>8.</span> Indemnity
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        You agree to indemnify, defend, and hold harmless the Shiga from any
        claim or demand (including without limitation fees and disbursements of
        attorneys and other professional advisers) made by any third party due
        to or arising out of:
      </p>
      <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          Your breach or alleged breach of the Agreement (including, without
          limitation, these terms).
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          Your misuse of the Services, or any smart contract and/or script
          related thereto.{" "}
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          Your violation of any laws, rules, regulations, codes, statutes,
          ordinances, or orders of any governmental or quasi- governmental
          authorities.{" "}
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          Your violation of the rights of any third party, including any
          intellectual property right, publicity, confidentiality, property, or
          privacy right.{" "}
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          Your use of a third-party product, service, and/or website.{" "}
        </li>
        <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
          Any misrepresentation made by you.{" "}
        </li>
      </ul>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        The indemnity obligation arising from this Agreement shall remain in
        full force and effect notwithstanding the termination of this Agreement.
      </p>
    </div>
  );
}

export default Indemnity;
