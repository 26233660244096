type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function Agreement({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Entire Agreement">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>15.</span> Entire Agreement
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        The Agreement (including, without limitation, these terms, and our
        Privacy Policy) and any policies or operating rules posted by us on the
        Services constitute the entire agreement and understanding between you
        and us and govern your use of the Services, superseding any prior or
        contemporaneous agreements, communications, and proposals, whether oral
        or written, between you and us. Any failure by us to exercise or enforce
        any right or provision of the Agreement shall not constitute a waiver of
        such right or provision.
      </p>
    </div>
  );
}

export default Agreement;
