type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function Termination({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Termination">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>11.</span> Termination
      </h1>
      <div className="flex flex-col gap-[15px] mt-[15px]">
        <div className="pl-[15px]">
          <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
            This Agreement is effective unless and until terminated by either
            you or us. You may terminate your Agreement with us at any time by
            ceasing all access to the site or the services. If, in our sole
            judgment, you fail, or we suspect that you have failed, to comply
            with any term or provision of the Agreement (including without
            limitation any provision of these Terms), we reserve the right to
            terminate our Agreement with you and deny you access to the
            services. We further reserve the right to restrict your access to
            the site or to stop providing you with all or a part of the services
            at any time and for no reason, including, without limitation, if we
            reasonably believe;
          </p>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Your use of the services exposes us to risk or liability.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              You are using the services for unlawful purposes; or
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              It is not commercially viable to continue providing you with our
              services.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              All of these are in addition to any other rights and remedies that
              may be available to us, whether in equity or at law, all of which
              we expressly reserve.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Termination;
