import { FC } from "react";
import { mail } from "../../common/svg";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useMobile } from "../../utlis";


// Contact data interface
interface SoonData {
  fullName: string;
  email: string;
}

// API response interface
interface ApiResponse {
  success: boolean;
  message: string;
}

const postComingSoon = async (soonData: SoonData): Promise<ApiResponse> => {
  const response = await fetch('https://americano.shiga.io/api/v1/subscribe', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(soonData),
  });

  if (!response.ok) {
    const errorData = await response.json(); // Parse the response body before logging
    const errorMessage = errorData?.message || 'Failed to send message';

    throw new Error(errorMessage);
  }

  return response.json();
};

const ComingSoon: FC = () => {
  const isMobile = useMobile();

  const { register, handleSubmit, reset } = useForm<SoonData>();

  const mutation = useMutation<ApiResponse, Error, SoonData>({
    mutationFn: postComingSoon,  // mutation function goes here
    onSuccess: () => {
      toast('Thank you for subscribing to Shiga!');
      reset();  // Reset the form after successful submission
    },
    onError: (error: Error) => {
      console.log("Error:", error);
      toast.error(`Error: ${error.message}`);
    },
  });

  const onSubmit = (data: SoonData) => {
    mutation.mutate(data);  // Trigger the mutation with form data
  };

  return (
    <div className="coming-wrapper relative w-full h-full">
       <section className="contact-container yolo w-full h-full bg-hbrand-25">
      <div className={`contact-hero relative bg-hbrand-25 flex items-center justify-center pt-[100px] ${isMobile ? "px-[24px] pb-[1.7rem]" : "px-[130px] pb-[3.5rem]"} z-10 w-[100%] h-full -mt-16`}>
        <div className={`contact-top flex ${isMobile ? "flex-col w-full pt-[.4rem]" : "pt-[3.5rem]"} items-start justify-center`}>
            <div className={`${isMobile ? "w-full" : "w-[52%]"} flex flex-col items-start justify-center gap-y-[26px] mt-2`}>
              <h3 className={`text-hgray-950 ${isMobile ? "text-[31.5px] leading-[38px]" : "text-[45px] leading-[52px]"} font-black font-satoshi`}>Be the first to know when we launch</h3>
              <p className="text-htext-label font-ibm text-[18px] font-normal leading-[26px] w-[91%]">Shiga is currently available to individuals and businesses who signed up for our waitlist. Fill out the form so we can notify you when we do a public launch.</p>
            </div>

            <div className={`${isMobile ? "w-full mt-11" : "w-[40%] ml-[38px]"}`}>
              <form className="w-full bg-white py-8 px-7 rounded-2xl border border-hgray-200 shadow-s-light" onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                <label htmlFor="name">
                  <div className="mb-1.5 flex items-center text-[12.5px] font-ibm font-medium">
                    <p className="mr-1 -mt-[2px] h-[10px] text-herror-600">*</p>
                    <p className="text-htext-label">Name</p>
                  </div>
                  <div className="input-cont w-full px-[12px] font-ibm border border-hgray-300 rounded-lg shadow-s-light">
                    <input
                      type="text"
                      className="w-full py-[10.6px] text-[12.6px] focus:outline-none border-0 rounded-lg bg-transparent"
                      placeholder=""
                      {...register('fullName', { required: true })}
                    />
                  </div>
                </label>

                <label htmlFor="email">
                  <div className="mb-1.5 mt-4 flex items-center text-[12.5px] font-ibm font-medium">
                    <p className="mr-1 -mt-[2px] h-[10px] text-herror-600">*</p>
                    <p className="text-htext-label">Email</p>
                  </div>
                  <div className="input-cont w-full flex items-center font-ibm pl-[12px] pr-[12px] border border-hgray-300 rounded-lg shadow-s-light">
                    <span
                      className=""
                      dangerouslySetInnerHTML={{ __html: mail }}
                    />

                    <input
                      type="text"
                      className="w-full ml-2 py-[10.6px] text-[12.6px] focus:outline-none border-0 rounded-lg bg-transparent"
                      placeholder=""
                      {...register('email', { required: true })}
                    />
                  </div>
                </label>
                {mutation.isError && <p className="mt-2 text-herror-600 text-[12.8px] font-ibm font-medium">{mutation.error.message}</p>}

                <div className="form-btns mt-12">
                <button type="submit" disabled={mutation.status === 'pending'} className="w-full py-[0.8rem] rounded-lg border border-hbrand-600 bg-hbrand-600 hover:bg-hbrand-700 hover:border-hbrand-700 text-center text-white text-[15.5px] font-ibm font-semibold shadow-s-light">
                  {mutation.status === 'pending' ? 'Submitting...' : 'Submit'}
                </button>
              </div>
                </fieldset>
              </form>
            </div>
        </div>
      </div>
       </section>

 <section className="contact-container w-full h-full bg-white">
      <div className={`business-hero relative flex items-center justify-center bg-white  ${isMobile ? "px-[24px] pb-[4rem] pt-[2.3rem]" : "px-[130px] pb-[6rem]"} z-10 w-[100%] h-full`}>
        <div className={`contact-bg relative bg-white ${isMobile ? "px-[26px] pt-[35px] mt-[20px] h-[520px]" : "px-[56px] pt-[48px] mt-[80px] h-[310px]"} pb-[22px] w-full ml-1 mx-auto border border-hgray-200 rounded-[24px] overflow-hidden`}>
          <div className={`about-us absolute h-full flex ${isMobile ? "flex-col w-[80%]" : "w-full"} items-center z-20`}>
            <div className={`"flex flex-col justify-start items-start ${isMobile ? "w-full  h-auto" : "w-[70%] h-full"}`}>
              <p className="text-htext-subdued text-[14.5px] font-ibm font-medium text-start">
              OUR VISION
              </p>
              <h1 className={`text-htext-main text-start ${isMobile ? "text-[22.5px] leading-[33px] mt-[35px]" : "text-[32px] leading-[40px] mt-[33px]"} font-satoshi font-black`}>
              Enabling Pan Africans to access blockchain-based financial solutions that can be used in the real world, anytime, anywhere.
              </h1>
            </div>

            <div className={`flex flex-col items-center justify-center w-[25%] h-auto ${isMobile ? "pt-[83px]" : "pb-[82px]"}`}>
              <p className="text-htext-main text-[16.5px] font-ibm font-semibold text-center">
                Shiga
              </p>
              <p className="text-htext-subdued text-[15.5px] font-ibm italic font-normal mt-[5px] text-center">
                noun
              </p>
              <p className="text-htext-main text-[17.5px] font-ibm font-normal mt-[20px] text-center">
                Freedom/access/enter
              </p>
            </div>
          </div>
        </div>
      </div>
 </section>
    </div>
  );
};

export default ComingSoon;
