import { MutableRefObject, useEffect, useRef, useState } from "react";
import Introduction from "./introduction";
import HowWeTransferData from "./howWeTransferData";
import ThirdParty from "./thirdParty";
import ContactInformation from "./contact";
import DataWeCollect from "./dataWeCollect";
import WhyWeCollectData from "./whyWeCollectData";
import SharingYourInformation from "./sharingInfo";
import StorateOrRetentionPeriod from "./storageRetention";
import ChangesToPolicy from "./changeToPolicy";
import YourRights from "./yourRights";

function PrivacyPolicy() {
  const date = new Date();
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
  };
  const monthYear = date.toLocaleDateString("en-US", options);

  const formattedDate = monthYear.replace(" ", ", ");

  const [refTitle, setRefTitle] = useState("");
  const introductionRef = useRef<HTMLDivElement | null>(null);
  const DataWeCollectRef = useRef<HTMLDivElement | null>(null);
  const WhyWeCollectDataRef = useRef<HTMLDivElement | null>(null);
  const SHaringYourInformationRef = useRef<HTMLDivElement | null>(null);
  const HowWeTransferDataRef = useRef<HTMLDivElement | null>(null);
  const StorageRetentionRef = useRef<HTMLDivElement | null>(null);
  const ChangesToPolicyRef = useRef<HTMLDivElement | null>(null);
  const ThirdPartyRef = useRef<HTMLDivElement | null>(null);
  const YourRightsRef = useRef<HTMLDivElement | null>(null);
  const ContactInformationRef = useRef<HTMLDivElement | null>(null);

  const termsTitle = [
    { titleName: "Introduction", refType: introductionRef },
    { titleName: "Data We Collect", refType: DataWeCollectRef },
    {
      titleName: "Why We Collect and Use Your Personal Data",
      refType: WhyWeCollectDataRef,
    },
    {
      titleName: "Sharing Your Information",
      refType: SHaringYourInformationRef,
    },
    {
      titleName: "How we transfer, store and protect your data",
      refType: HowWeTransferDataRef,
    },
    { titleName: "Third-Party Sites", refType: ThirdPartyRef },
    { titleName: "Storage/Retention Period", refType: StorageRetentionRef },
    { titleName: "Changes to this Policy", refType: ChangesToPolicyRef },
    { titleName: "Your Rights", refType: YourRightsRef },
    { titleName: "Contact Us", refType: ContactInformationRef },
  ];

  const scrolLWithUseRef = (incoming: MutableRefObject<HTMLDivElement>) => {
    setRefTitle(incoming.current.title);
    window.scrollTo({
      top: incoming.current.offsetTop - 100,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setRefTitle("Introduction");
    return;
  }, []);

  return (
    <div className="bg-[white] h-full w-full pb-[250px] md:pb-[350px]">
      <div className="w-full pt-[100px] pb-[60px] bg-[#E6E6FF99]/25">
        <h1 className="text-[36px] font-ibm text-center text-primary-main leading-[50px] tracking-normal font-bold md:text-[25px]">
          PRIVACY POLICY
        </h1>
      </div>
      <div className="flex justify-center">
        <div className="max-w-[1300px] h-full pb-[50px]">
          <div className=" lg:px-6 xl:px-12 w-full h-full px-14 xlsm:px-3">
            <div className=" flex gap-[40px]">
              <div className="w-[35%] h-full md:hidden">
                <div className="sticky top-[100px] cursor-pointer">
                  <p className="text-[#2a3342] text-[20px] leading-[30px] font-[600] mt-[40px] tracking-normal">
                    In this section{" "}
                  </p>
                  <div className="mt-[15px]">
                    {termsTitle.map((item) => {
                      return (
                        <div key={item.titleName}>
                          <p
                            onClick={() =>
                              scrolLWithUseRef(
                                item.refType as unknown as MutableRefObject<HTMLDivElement>
                              )
                            }
                            className={
                              refTitle === item.titleName
                                ? `text-hbrand-500 text-[16px] bg-[#E6E6FF99]/25 py-4 px-4 leading-[150%] font-[600] tracking-normal mb-2`
                                : `text-[16px] leading-[150%] text-[#556987] py-1 tracking-normal mb-2`
                            }
                          >
                            {item.titleName}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="w-[65%] md:w-full h-full">
                <div className="w-full">
                  <div className="mt-[40px] flex items-center gap-[8px]">
                    <p className="text-[#556987] text-[14px] leading-[30px] font-[600]  tracking-normal">
                      By using the Shiga application and services you have
                      agreed to the terms detailed below. Kindly review
                      carefully.
                    </p>
                  </div>
                  <div className="mt-[20px] flex items-center gap-[8px]">
                    <div>
                      <img
                        src="/assets/calendar.svg"
                        alt="icon"
                        className="w-[20px] h-[20px]"
                      />
                    </div>
                    <p className="text-[#556987] text-[14px] leading-[30px] font-[600]  tracking-normal">
                      Last updated: {formattedDate}
                    </p>
                  </div>
                  <Introduction pageRef={introductionRef} />
                  <DataWeCollect pageRef={DataWeCollectRef} />
                  <WhyWeCollectData pageRef={WhyWeCollectDataRef} />
                  <SharingYourInformation pageRef={SHaringYourInformationRef} />
                  <HowWeTransferData pageRef={HowWeTransferDataRef} />
                  <ThirdParty pageRef={ThirdPartyRef} />
                  <StorateOrRetentionPeriod pageRef={StorageRetentionRef} />
                  <ChangesToPolicy pageRef={ChangesToPolicyRef} />
                  <YourRights pageRef={YourRightsRef} />
                  <ContactInformation pageRef={ContactInformationRef} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
