type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function SeverabilityB({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Severability">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>13.</span> Severability
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        If a provision of this Agreement is held to be illegal, void, or
        unenforceable, in whole or in part, under an enactment or rule of law,
        it shall to that extent be deemed not to form part of this Agreement and
        the legality or enforceability of the remainder of this Agreement shall
        not be affected. Such determination shall not affect the validity and
        enforceability of any other remaining provisions.
      </p>
    </div>
  );
}

export default SeverabilityB;
