import { FC, useEffect, useState, useRef } from "react";
import { arrowrightDarkerSVG, homeIcon, dotSVG, minus, plus } from "../../common/svg";
import { Link } from "react-router-dom";
import Sidebar from "../../common/sidebar";
import BlogDetailSection from "./blogDetailsSection";
import { useMobile } from "../../utlis";

export type Section = "keyTakeaways" | string; 

const BlogDetails: FC = () => {
  // const navigate = useNavigate();
  const isMobile = useMobile();

  const [selectedSection, setSelectedSection] = useState<Section>("keyTakeaways");
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [openFAQIndex, setOpenFAQIndex] = useState<{
    section: number | null;
    index: number | null;
  }>({ section: null, index: null });

  // Toggle function to handle opening/closing of the FAQs
  const toggleFAQ = (section: number, index: number) => {
    // If the same FAQ is clicked, close it; otherwise, open the new one
    if (openFAQIndex.section === section && openFAQIndex.index === index) {
      setOpenFAQIndex({ section: null, index: null });
    } else {
      setOpenFAQIndex({ section, index });
    }
  };

  const faqContent1 = [
    {
      question: "What is Shiga?",
      answer:
        "Shiga Digital is leading the way in creating a cutting-edge blockchain-based bank with a special focus on Pan Africa. Say goodbye to traditional finance and hello to the new era of financial empowerment for Pan-Africa! We're building the core components to redefine the financial landscape, providing liquidity through stablecoins, seamless payments, and access to real-world assets. Our self-custodial wallets will empower you to take control of your assets like never before. Shiga is paving the way for the next wave of enterprises and individuals seeking innovative financial services. Get ready to join the movement!",
    },
    {
      question: "Why did we build Shiga?",
      answer:
        "Blockchain-based finance has the potential to transform access to finance, with a handful of companies already leading this revolution. However, to make this work for the benefit of all and truly achieve scale, we need to fix the disconnect between the current system and the immaturity of the new system. Shiga is driving towards a future where both systems coexist harmoniously, bringing the best of both worlds to businesses, banks, and payment providers.",
    },
    {
      question: "Is Shiga regulated?",
      answer:
        "Shiga's VASP is registered in the Czech Republic, which enables Shiga to engage in digital asset services in Europe. Specifically, this includes the intermediation of trade and services, wholesale and retail trade, software provision, information technology consultancy, data processing, hosting and related activities, web portals, and virtual asset services.",
    },
    {
      question: "How is shiga regulated out of europe?",
      answer:
        "Outside of Europe, Shiga Digital, Inc. is a Delaware-based C Corporation Leveraging regulated entities to provision subsequent gateways in supported regions.",
    },
  ];

  const faqContent2 = [
    {
      question: "What exactly are stablecoins?",
      answer:
        "Stablecoins are digital currencies designed to provide stability. They are pegged to stable assets like the US dollar and backed by equivalent US Treasuries reserves. Issued by regulated entities such as Circle or Coinbase, stablecoins combine cryptocurrencies' advantages with traditional currencies' stability.",
    },
    {
      question: "What is a self-custody wallet? ",
      answer:
        " Non-custodial means that you're always in control. Unlike traditional banks, Shiga never has unilateral access to your funds. There’s no rehypothecation without your consent—your assets are yours. Our platform ensures complete transparency and control, setting a new financial freedom and security standard.",
    },
    {
      question: "How long does it take for transactions to process?",
      answer:
        "We aim to make every Shiga Powered transaction feel instant. Most portal transactions are processed in less than five minutes using your Shiga portals. Shiga uses blockchain technology to process all orders instantly (sending them for clearing immediately). Transaction times may vary depending on local banking processing times.",
    },
    {
      question: "Does Shiga have a mobile application?",
      answer: "Coming soon 😃",
    },
  ];

  const changeSelectedSection = (section: Section) => {
    const element = document.getElementById(section);
    const container = scrollContainerRef.current;

    if (element && container) {
      const containerTop = container.getBoundingClientRect().top;
      const elementTop = element.getBoundingClientRect().top;
      const offset = elementTop - containerTop;

      container.scrollTo({
        top: offset,
        behavior: "smooth",
      });

      setSelectedSection(section); // Update selected section
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (!container) return;

    const sections = container.querySelectorAll(".section");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const id = entry.target.getAttribute("id");
            if (id) {
              setSelectedSection(id as Section); // Update selected section
            }
          }
        });
      },
      {
        root: container, // Observe only within the scrollable container
        threshold: 0.6, // 60% of the section must be visible
      }
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  // const changeSelectedSection = (section: Section) => {
  //   setSelectedSection(section);
  // }


  return (
    <div className="blog-details-wrapper relative pb-[3rem] h-full w-full bg-white">
      <section className="blog-details-container w-full h-full bg-[#FEEAE7]">
      <div
        className={`blog-details-hero relative flex flex-col items-center justify-start bg-[#FEEAE7] pb-8 z-10 w-full ${
          isMobile ? "h-fit pt-[2.8rem]" : "h-fit pt-12 px-[130px]"
        } -mt-16`}
      >
        {!isMobile && (
          <div className="topnav w-full flex items-center justify-start pt-[120px]">
            <Link to="/">
              <span
                className="cursor-pointer"
                dangerouslySetInnerHTML={{ __html: homeIcon }}
              />
            </Link>
            <span
              className="px-4"
              dangerouslySetInnerHTML={{ __html: arrowrightDarkerSVG }}
            />
            <Link to="/blog">
              <p className="text-htext-label text-[12.5px] font-ibm font-medium cursor-pointer">
                Blog
              </p>
            </Link>
            <span
              className="px-4"
              dangerouslySetInnerHTML={{ __html: arrowrightDarkerSVG }}
            />
            <p className="text-htext-disabled text-[12.5px] font-ibm font-medium">
              Swap local currency to USDC, USDT
            </p>
          </div>
        )}

  <div className="w-full">
          <div
            className={`relative flex flex-col items-start justify-start ${
              isMobile
                ? "w-full px-[24px] pt-[4.5rem]"
                : " w-[55rem] pt-[2rem] mt-[2rem] pb-[.8rem]"
            }`}
          >
            <h1
              className={`text-left text-hgray-950 ${
                isMobile
                  ? "text-[30.5px] leading-[38px] text-left"
                  : "text-[46.7px] leading-[60px] tracking-[-0.96px]"
              } font-satoshi font-black`}
            >
              From Fiat to Stablecoins: Simplifying the Journey with Shiga’s
              Seamless Portal
            </h1>
            <div className="bottom-read text-htext-disabled text-[15px] font-normal flex items-center justify-center mt-8">
              <p>Shiga Basics</p>
              <span
                className="mx-[8px]"
                dangerouslySetInnerHTML={{ __html: dotSVG }}
              />
              <p>4 min read</p>
              <span
                className="mx-[8px]"
                dangerouslySetInnerHTML={{ __html: dotSVG }}
              />
              <p>October 7, 2024</p>
            </div>
        </div>
  </div>
      </div>
      </section>

      <section className="blog-details-container w-full h-full bg-white">
        <div className={`blog-details-content pt-[64px] pb-[40px] ${isMobile ? "px-[24px]" : "px-[130px]"} h-full w-full overflow-hidden`}>
        <h4 className="text-htext-label text-[14.5px] font-normal mb-[12px]">ON THIS PAGE</h4>

          <div className={`sixm:block grid grid-cols-[380px_1fr] gap-x-24 ${isMobile ? "grid-cols-1" : ""}`}>
          <div className={`relative h-full`}>
          <Sidebar
                section={selectedSection}
                changeSelectedSection={changeSelectedSection}
              />
          </div>

          <div ref={scrollContainerRef} className={`details-content relative pb-2 ${isMobile ? "w-full h-full" : "w-[670px] h-[41rem]"} overflow-y-scroll hide-scrollbar`}>
            <BlogDetailSection section={selectedSection} />
          </div>
          </div>
        </div>

      <div className="home-faq relative bg-white">
        <div className={`faq-cont ${isMobile ? "px-[24px] pt-[5px] pb-[23rem]" : "pt-[88px] pb-[17rem] px-[148px]"}`}>
          <h2 className="faq-header font-satoshi font-black text-hgray-950 text-[43.5px] text-start">
            FAQ
          </h2>
          <div className={`w-full flex ${isMobile ? "flex-col" : ""} items-start justify-between flex-wrap mt-6`}>
            {/* First Column of FAQs */}
            <div className={`flex flex-col items-start justify-center ${isMobile ? "w-full" : "w-[48%]"}`}>
              {faqContent1.map((faq, index) => (
                <div
                  className={`faq-card w-full cursor-pointer ${
                    index === 0
                      ? "border-y border-y-hgray-200"
                      : "border-b border-b-hgray-200"
                  } py-5`}
                  key={index}
                  onClick={() => toggleFAQ(1, index)}
                >
                  <div className="flex items-center justify-between">
                    <h3 className={`font-ibm font-bold text-[15.5px] text-htext-main ${isMobile ? "pr-1" : ""}`}>
                      {faq.question}
                    </h3>
                    <span
                      className=""
                      dangerouslySetInnerHTML={{
                        __html:
                          openFAQIndex.section === 1 &&
                          openFAQIndex.index === index
                            ? minus
                            : plus,
                      }}
                    />
                  </div>
                  {openFAQIndex.section === 1 &&
                    openFAQIndex.index === index && (
                      <div className="toggle-content mt-2.5">
                        <p className="font-ibm font-normal text-[14.5px] text-htext-label w-[90%]">
                          {faq.answer}
                        </p>
                      </div>
                    )}
                </div>
              ))}
            </div>

            {/* Second Column of FAQs */}
            <div className={`flex flex-col items-start justify-center ${isMobile ? "w-full" : "w-[48%]"}`}>
              {faqContent2.map((faq, index) => (
                <div
                  className={`faq-card w-full cursor-pointer ${
                    isMobile && index === 0
                      ? "border-b border-b-hgray-200" : !isMobile && index === 0 ?
                      "border-y border-y-hgray-200"
                      : "border-b border-b-hgray-200"
                  } py-5`}
                  key={index}
                  onClick={() => toggleFAQ(2, index)}
                >
                  <div className="flex items-center justify-between">
                    <h3 className="font-ibm font-bold text-[15.5px] text-htext-main">
                      {faq.question}
                    </h3>
                    <span
                      className=""
                      dangerouslySetInnerHTML={{
                        __html:
                          openFAQIndex.section === 2 &&
                          openFAQIndex.index === index
                            ? minus
                            : plus,
                      }}
                    />
                  </div>
                  {openFAQIndex.section === 2 &&
                    openFAQIndex.index === index && (
                      <div className="toggle-content mt-2.5">
                        <p className="font-ibm font-normal text-[14.5px] text-htext-label w-[90%]">
                          {faq.answer}
                        </p>
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>
          
        </div>
      </div>
     </section>
    </div>
  );
};

export default BlogDetails;
