type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function ChangesToPolicy({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Changes to this Policy">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>8.</span> Changes to this Policy
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        We may update this policy from time to time to ensure compliance with
        applicable laws and/or making changes to our services. We recommend you
        check this page/website from time to time to inform yourself of any
        changes.{" "}
      </p>
    </div>
  );
}

export default ChangesToPolicy;
