type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function WaiverInjunction({ pageRef }: IPageRef) {
  return (
    <div
      className="mt-[25px]"
      ref={pageRef}
      title="Waiver of Injunction or other Equitable Relief"
    >
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>10.</span> Waiver of Injunction or other Equitable Relief
      </h1>
      <p className="text-[#2a3342] text-[18px] xlsm:text-[17px] leading-[28px] font-[400] mt-[15px] tracking-normal ">
        To the maximum extent permitted by law, you agree that you will not be
        permitted to obtain an injunction or other equitable relief of any kind,
        such as a Court order or other action that may interfere with or prevent
        the development or exploitation of the services, or any other creation
        such as; website, application, content, submission, product, service, or
        intellectual property owned, licensed, used or controlled by Shiga.
      </p>
    </div>
  );
}

export default WaiverInjunction;
