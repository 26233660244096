type IPageRef = {
  pageRef: React.MutableRefObject<HTMLDivElement | null>;
};

function DisputeResolution({ pageRef }: IPageRef) {
  return (
    <div className="mt-[25px]" ref={pageRef} title="Dispute Resolution">
      <h1 className="font-[700] text-[24px] xlsm:text-[20px] leading-[33px] xlsm:leading-[30px] text-[#2a3342] tracking-normal">
        <span>9.</span> Dispute Resolution
      </h1>
      <div className="flex flex-col gap-[15px] mt-[15px]">
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>9.1</span> Amicable Settlement
          </h1>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              In the event of a dispute arising between you and Shiga relating
              to the agreement or the Services, the Party in dispute shall
              notify the Party immediately in writing.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              The Party receiving such notice shall have thirty (30) days to
              respond to the notice.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Within sixty (60) days after the aggrieved party sent the initial
              notice, the parties shall meet and confer in good faith by
              videoconference, or by telephone, to try to resolve the dispute.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              The Parties shall have ninety (90) days after the aggrieved Party
              sent the initial notice to resolve the dispute amicably.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              If the Parties fail to settle such difference or dispute by
              amicable agreement, either Party may submit the dispute to
              Arbitration as set forth below.
            </li>
          </ul>
        </div>
        <div className="pl-[15px]">
          <h1 className="font-[700] text-[20px] xlsm:text-[16px] leading-[33px] xlsm:leading-[28px] text-[#2a3342] tracking-normal">
            <span>9.2</span> Arbitration
          </h1>
          <ul className="list-[disc] px-[35px] xlsm:pr-[19px] marker:text-hbrand-500 ">
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              Both Parties shall bear the cost equally.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              The Party receiving such notice shall have thirty (30) days to
              respond to the notice.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              The dispute shall be resolved through binding arbitration in
              accordance with the International Arbitration Rules of the
              International Centre for Dispute Resolution.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              The seat of arbitration shall be decided upon by Shiga.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              The language of the arbitration shall be English.
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              The arbitrator(s) shall have experience adjudicating matters
              involving Internet technology, software applications, financial
              transactions, and, ideally, blockchain technology.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              The arbitrator’s award of damages must be consistent with the
              terms of the “Limitation of Liability” subsection of these Terms
              as to the types and amounts of damages for which a party may be
              held liable.{" "}
            </li>
            <li className="text-[#2a3342] text-[18px] xlsm:text-[16px] leading-[28px] font-[400] mt-[15px] tracking-normal">
              The prevailing party will be entitled to an award of their
              reasonable attorney’s fees and costs. Except as may be required by
              law, neither a party nor its representatives may disclose the
              existence, content, or results of any arbitration hereunder
              without the prior written consent of all parties.{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DisputeResolution;
